/**
 * 会社ページ用のスクリプト
 */
if (is_route('company.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        company_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
 window.company_event_set = function() {

    /*---- 設定の表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'setting-modal', ['id']);
    });

    /*---- 設定の保存時 ----*/

    // 設定実行時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {
            let keys = Object.keys(results);
            if (keys.find(key => key == 'holding')) {
                $('[data-replace-name-holding-id=' + results.holding.id + ']').text(results.holding.name);   
            }
            if (keys.find(key => key == 'company')) {
                $('[data-replace-name-company-id=' + results.company.id + ']').text(results.company.name);   
            }
        });
    });

    /*---- 削除確認の表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-confirm-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            // ツールバーの更新が必要なのでリロードする
            location.reload();
        });
    });
}