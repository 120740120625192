/**
 * 社員台帳ページ用のスクリプト
 */
if (is_route('user.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        user_event_set();
        user_setteing_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.user_event_set = function() {

    // ボタンクリック時のバブリングを禁止
    $(document).on('click', '.user_license_file_link', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['id']);
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id', 'type']);
    });

    /*---- 設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {
            $('[data-detail-modal=user-detail-modal][data-id=' + results.user.id + ']').trigger('click');
        }, function() {
            user_set_steper_error();
        });
    });

    /*---- 一括削除チェックボックス操作時 ----*/

    // 一括のチェックボックスクリック時
    $(document).on('click', '#user-all-delete', function () {
        $(".user-delete").prop('checked', $(this).prop('checked'));
    });
    // 一覧のチェックボックスクリック時
    $(document).on('click', '.user-delete', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });
    // 一括・一覧のチェックボックス変更時
    $(document).on('change', '#user-all-delete, .user-delete', function () {
        // 一覧のチェック状態を判定し削除ボタンを有効化
        $('#user-multi-delete-button').prop('disabled', ($('.user-delete:checked').length == 0));
    });

    /*---- 除確認時 ----*/

    // 一括削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        var ids = ($(this).data('id') == null ? [] : [$(this).data('id')]);
        if ($(this).data('id') == null) {
            $('.user-delete[name="ids[]"]:checked').map(function() {
                ids.push($(this).val());
            });
        }
        if (ids.length > 0) {
            _modal.create_with_data(this, 'confirm-modal', {'ids': ids});
        }
    });

    /*---- 削除処理 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            _modal.hide('#user-detail-modal');
        });
    });

    /*---- CSVダウンロード時 ----*/

    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });

    /*---- CSVアップロード表示時 ----*/

    // CSVアップロード表示時
    $(document).on('click', '#search-form-action_upload', function () {
        // 初期化処理
        $('#user-csv-setting-modal-csv-submit-message').addClass('display-none');
        $('#user-csv-setting-modal-csv-submit-message').empty();
        $('#user-csv-setting-modal-csv-error').addClass('display-none');
        $('#user-csv-setting-modal-csv-error').empty();
        $('#user-csv-setting-modal-csv').val('');
    });

    /*---- CSVアップロード確認時 ----*/

    // CSVアップロード確認時
    $(document).on('change', '#user-csv-setting-modal-csv', function () {
        let modalId = '#user-csv-setting-modal';
        let formId = '#user-csv-setting-modal-form';
        let action = $(formId).data('confirm-action');
        let datas = new FormData($(formId).get(0));
        $(modalId + '-csv-error').addClass('display-none');
        $(modalId + '-csv-error').empty();
        $(modalId + '-csv-submit-message').addClass('display-none');
        $(modalId + '-csv-submit-message').empty();
        $("[data-csv-save-modal]").prop("disabled", true);
        // 送信
        _ajax.file(action, datas, function (results) {
            $(modalId + '-csv-submit-message').removeClass('display-none');
            $(modalId + '-csv-submit-message').text(results.messages[0]);
            $("[data-csv-save-modal]").prop("disabled", false);
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
            $(this).val('');
            // フォームにエラーメッセージを配置
            $.each(xhr.responseJSON.errors, function(name, message) {
                $(modalId + '-csv-error').removeClass('display-none');
                $(modalId + '-csv-error').append($('<li></li>').text(message));
            });
        });
    });

    /*---- CSVアップロード時 ----*/

    // 設定表示時
    $(document).on('click', '[data-csv-save-modal]', function () {
        let modalId = '#user-csv-setting-modal';
        let formId = '#user-csv-setting-modal-form';
        let action = $(formId).attr('action');
        let datas = new FormData($(formId).get(0));
        // 送信前にエラーをリセット
        _error.reset()
        // 送信
        _ajax.file(action, datas, function (results) {
            // 完了メッセージをセットする
            _complete.set(results);
            // モーダルを閉じる
            _modal.hide(modalId);
            // コンテンツの読込
            load_contents();
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.omitted(xhr, status, errorThrown);
        });
    });

    $(document).on('click', '.transfered .btn-group', function (event) {
        event.preventDefault();
    });
}
/**
 * 設定モーダル表示時のイベント設定
 * @uses ダッシュボードからモーダル表示する際にも呼び出される
 */
window.user_setteing_event_set = function() {

    /*---- 業種の選択時 ----*/

    // 業種の選択時
    $(document).on('change', '.occupation-check input[name="occupation_ids[]"]', function() {
        let occupationTypes = [];
        $(this).parents('.occupation-check').find('input[name="occupation_ids[]"]').each(function() {
            if ($(this).prop('checked') && $(this).data('occupation-type') > 0) {
                occupationTypes.push($(this).data('occupation-type'));
            }
        });
        $('.area_driver').toggleClass('display-none', !(occupationTypes.find(v => v == 1) > 0));
        $('.area_operation').toggleClass('display-none', !(occupationTypes.find(v => v == 2) > 0));
        $('.area_maintenance').toggleClass('display-none', !(occupationTypes.find(v => v == 3) > 0));
        $('.area_supervision').toggleClass('display-none', !(occupationTypes.find(v => v == 4) > 0));
    });

    /*---- ステップボタンクリック時（新規登録時） ----*/

    // 前へ
    $(document).on('click', '[data-prev-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let prevPart = $(this).data('prev-step');
        let prevPartTrigger = create_id(prevPart + '-trigger');
        let prevPartSubmit = create_id(prevPart + '-submit');
        $(currentPartSubmit).addClass('display-none');
        $(prevPartSubmit).removeClass('display-none');
        bs_step_trigger(prevPartTrigger);
    });

    // 次へ
    $(document).on('click', '[data-next-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let nextPart = $(this).data('next-step');
        let nextPartTrigger = create_id(nextPart + '-trigger');
        let nextPartSubmit = create_id(nextPart + '-submit');
        // ModalとPartセレクターの指定
        let modalId = '#user-setting-modal';
        let formId = '#user-setting-modal-form';
        let action = $(formId).data('confirm-action');
        // 確認対象のパートをパラメータにセット
        $('#user-setting-modal-confirm_step').val($(this).data('confirm-step'));
        // 送信前にエラーをリセット
        _error.reset()
        user_set_steper_error(false);
        // 確認用のpost送信
        _ajax.confirm_submit(formId, action, function (results) {
            $(currentPartSubmit).addClass('display-none');
            $(nextPartSubmit).removeClass('display-none');
            bs_step_trigger(nextPartTrigger);
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.set(modalId, xhr, status, errorThrown);
            // ステップエラーを表示
            user_set_steper_error();
        });
    });

    /*---- 資格情報の追加ボタンを押下(資格入力項目を追加する) ----*/

    $(document).on('click', '#license-part button[name="add_license"]', function(){
        var count = $('#license-part .card').length;
        var card = $('#license-part .card:first').clone();

        // 削除項目あれば消す
        card.find('input[name="license_delete[]"]').remove();
        card.find('#label_license_delete').remove();

        // 追加要素の初期化
        card.find('input').each(function() {
            $(this).val('');
            var id = $(this).attr('id');
            var newId = id.replace(/_0/g , '_' + count);
            var label = card.find('label[for="' + id + '"]');
            var error = card.find('#' + id + '-errors');

            // ID書き換え
            $(this).attr('id', newId);
            label.attr('for', newId);

            // error初期化
            if (error.length > 0) {
                error.attr('id', newId + '-errors');
                error.find('span').html('');
            }
        });

        // 最後に追加
        card.insertAfter('#license-part .card:last');

        initialize();

        // 50件になったら追加ボタン殺す
        if (count + 1 >= 50) {
            $(this).prop('disabled', true);
        }
    });
}

/**
 * ステッパーへのエラー表示処理
 * @param {*} showError
 */
window.user_set_steper_error = function (showError = true) {
    if (showError) {
        $('#base-part-trigger').toggleClass('error', ($('#base-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#belong-part-trigger').toggleClass('error', ($('#belong-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#occupation-part-trigger').toggleClass('error', ($('#occupation-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#license-part-trigger').toggleClass('error', ($('#license-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#insurance-part-trigger').toggleClass('error', ($('#insurance-part').find('.invalid-feedback:not(.copy)').length > 0));
    } else {
        $('#base-part-trigger').removeClass('error');
        $('#belong-part-trigger').removeClass('error');
        $('#occupation-part-trigger').removeClass('error');
        $('#license-part-trigger').removeClass('error');
        $('#insurance-part-trigger').removeClass('error');
    }
}
