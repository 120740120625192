export function setupFileUpload(configs) {
    configs.forEach(config => {
        const { fileName, maxFileSize = 5, appendDataForConfirm = null, appendDataForSave = null } = config;
        setupFileUploadHandlers({fileName, maxFileSize, appendDataForConfirm, appendDataForSave})
    });
}

function setupFileUploadHandlers({ fileName, maxFileSize = 5, appendDataForConfirm = null, appendDataForSave = null }) {
    // コンテンツ読み込み後
    $(document).ready(function () {
        // （エリア以外でドラッグの場合）ブラウザのデフォルト動作を回避①
        document.addEventListener('drop', function (e) {
            e.preventDefault()
        })
        // （エリア以外でドラッグの場合）ブラウザのデフォルト動作を回避②
        document.addEventListener('dragover', function (e) {
            e.preventDefault()
        })
    
        // ドラッグしたままエリアに乗った＆外れたとき
        $(document).on('dragover', `#${fileName}-drop_area`, function (event) {
            event.preventDefault();
            $(this).css("background-color", "#999999");
        });
        $(document).on('dragleave', `#${fileName}-drop_area`, function (event) {
            event.preventDefault();
            $(this).css("background-color", "transparent");
        });
        // ドラッグした時
        $(document).on('drop', `#${fileName}-drop_area`, function (event) {
            $(this).css("background-color", "transparent");

            let accept = $(`#${fileName}`).prop('accept');
            var uploadableType = accept.split(',');
            let org_e = event;
            if (event.originalEvent) {
                org_e = event.originalEvent;
            }
    
            org_e.preventDefault();
            var uploadable = true;
            for(const element of  org_e.dataTransfer.files){
                if(!uploadableType.includes(element.type)){
                    toastr.warning(`ファイル形式が正しくありません。`);
                    uploadable = false;
                    break;
                }
            }
            if(uploadable){
                $(`#${fileName}`)[0].files = org_e.dataTransfer.files;
                $(`#${fileName}`).change();
            }
        });
    
        // アップロード確認
        $(document).on('change', `#${fileName}`, function () {
            // メッセージ初期化
            $(`#${fileName}-error-message`).addClass('d-none');
            $(`#${fileName}-error-message`).empty();
            $(`#${fileName}-submit-message`).addClass('d-none');
            $(`#${fileName}-submit-message`).empty();
            // 保存ボタン非活性
            $('[data-upload-save-modal]').prop("disabled", true);

            const form = $(this).parents('form');
            if (!validateFileExists(form) || !validateOtherFormNotHasFile(form) || !validateFileSize(form)) {
                resetFileInput(form);
                return false;
            }
            
            const action = form.data('confirm-action');
            const datas = new FormData(form.get(0));
            if (typeof appendDataForConfirm === 'function') {
                appendDataForConfirm(datas);
            }
    
            // 送信
            _ajax.file(action, datas, function (results) {
                $(`#${fileName}-submit-message`).removeClass('d-none');
                $(`#${fileName}-submit-message`).text(results.messages[0]);
                // アップロード情報保存イベントの設定
                setUpFileSaveHandler();
                $('[data-upload-save-modal]').prop("disabled", false);
            }, function (xhr, status, errorThrown) {
                if (xhr.status === 502) {
                    toastr.warning('リクエストがタイムアウトしました。ファイルサイズが大きすぎる可能性がありますので、小さいファイルを試してください。');
                    return;
                }
                _error.omitted(xhr, status, errorThrown);
                // フォームにエラーメッセージを配置
                $.each(xhr.responseJSON.errors, function(name, message) {
                    $(`#${fileName}-error-message`).removeClass('d-none');
                    $(`#${fileName}-error-message`).append($('<li></li>').text(message));
                });
            });
        });

        // アップロード情報保存イベントの設定
        function setUpFileSaveHandler() {
            // HACK: ファイルアップロードのたびに呼ばれることを想定するため、毎回イベントを削除してから追加する
            $(document).off('click', '[data-upload-save-modal]').on('click', `[data-upload-save-modal]`, function () {
                // 全てのフォームを探索
                let selectedForm = null;
                $(this).parents('.util-upload').find('form').each(function() {
                    const form = $(this);
                    const fileInput = form.find('input[type="file"]')[0];
                    if (fileInput && fileInput.files && fileInput.files.length > 0) {
                       selectedForm = form;
                       return false;
                    }
                });
                const action = selectedForm.attr('action');
                const datas = new FormData(selectedForm.get(0));
                const modalId = $(this).parents('.modal').attr('id');
                if (typeof appendDataForSave === 'function') {
                    appendDataForSave(datas);
                }

                if($(this).data('save2') == true) { //追加ボタン（仮申込など）がクリックされた場合
                    //data-save2paramの情報を付加
                    const save2param = $(this).data('save2param');
                    // 各key-valueをdatas(FormData)に追加
                    for (const key in save2param) {
                        if (save2param.hasOwnProperty(key)) {
                            datas.append(key, save2param[key]);
                        }
                    }
                }

               // 送信前にエラーをリセット
               _error.reset()
               // 送信
               _ajax.file(action, datas, function (results) {
                   // 完了メッセージをセットする
                   _complete.set(results);
                   // モーダルを閉じる
                   _modal.hide(modalId);
                   // コンテンツの読込
                   load_contents();
               }, function(xhr, status, errorThrown){
                   // エラーをセットする
                   _error.omitted(xhr, status, errorThrown);
               });
           });
        }
    
        // ファイルが選択されていることをバリデーション
        function validateFileExists(thisForm) {
            let file = thisForm.find('input[type="file"]').get(0);
            if (!file) {
                return false;
            }
            if (file.files && file.files.length > 0) {
                return true;
            }
            return false;
        }

        // 他の兄弟要素にファイルが選択されていないことをバリデーション
        function validateOtherFormNotHasFile(thisForm) {
            let hasFile = false;

            thisForm.siblings('form').each(function() {
                const form = $(this);
                if (form[0] !== thisForm[0]) { 
                    // 他のフォームにファイルが選択されているか確認
                    form.find('input[type="file"]').each(function() {
                        if (this.files && this.files.length > 0) {
                            hasFile = true;
                            return false; // 内部ループを抜ける
                        }
                    });
                }
                if (hasFile) {
                    return false; // 外部ループを抜ける
                }
            });
            if (hasFile) {
                toastr.warning(`他のフォームにファイルが選択されています。`);
                return false;
            }

            return true;
        }

        // ファイルサイズバリデーション
        function validateFileSize(thisForm) {
            // 容量制限
            let file = thisForm.find('input[type="file"]');
            if (!file) {
                return true;
            }
            const maxFileSizeMegaByte = maxFileSize * 1024 * 1024;
            if (file.size > maxFileSizeMegaByte) {
                toastr.warning(`ファイルサイズは${maxFileSize}MB以下でお願いいたします。`);
                return false;
            }
            return true;
        }

        // フォームに選択されているファイルを空にする
        function resetFileInput(form) {
            form.find('input[type="file"]').each(function() {
                $(this).val('');
            });
        }
    });
}