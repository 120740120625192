/**
 * ツールチップを初期化
 */
window.initialize_tooltip = function() {
    // ツールチップを初期化
    $('[data-toggle="tooltip"]').tooltip();
}
/**
 * select2を初期化
 */
window.initialize_select2 = function() {
    // select2を初期化
    $('select.select2').each(function() {
        let noResults = $(this).data('no-results');
        $(this).select2({
            width: "100%",
            language: { "noResults": function() {
                return noResults;
            }},
        });
    });
    // 親の更新処理
    function parentSelectUpdate(elm, selector) {
        let parent = $(elm).parents('form').find(selector);
        if ($(elm).val() == '' && $(parent).val() != '') {
            $(parent).trigger('change');
        }
    }
    // nullで保存している場合の初期値の取得処理（親がform内に存在している場合に更新を行う）
    $('select.company-select').each(function() {
        parentSelectUpdate(this, 'select.holding-select');
    });
    $('select.section-select').each(function() {
        parentSelectUpdate(this, 'select.company-select');
    });
    $('select.user-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.driver-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.account-user-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.rollcall-assistant-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.rollcall-enforcer-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.rollcall-authorizer-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.vehicle-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
}
/**
 * マルチセレクトを初期化
 */
 window.initialize_multiselect = function() {
    // マルチセレクトを初期化
    $('select.multi-select').each(function() {
        let noResults = $(this).data('no-results');
        let countSelected = $(this).data('count-selected');
        let allSelect = $(this).data('all-select');
        let allSelected = $(this).data('all-selected');
        $(this).multipleSelect({
            formatSelectAll: function () {
                return allSelect;
            },
            formatAllSelected: function () {
                return allSelected;
            },
            formatNoMatchesFound: function () {
                return noResults;
            },
            formatCountSelected: function (count, total) {
                return countSelected.replace(':count', count).replace(':total', total);
            }
        });
    });
}
/**
 * デートピッカーを初期化
 * @param {*} type all, time-minutes, time-seconds, date, datetime, datetime-seconds, month
 */
window.initialize_datepicker = function(type = 'all') {

    /*---- 時分選択フォームの初期化 ----*/

    if (type == 'all' || type == 'time-minutes') {
        $('.time-minutes-picker').each(function() {
            $(this).datetimepicker({
                datepicker: false,
                format: 'HH:mm',
                locale: 'ja',
                icons: {
                    time: 'far fa-clock',
                }
            });
            $(this).off('focus');
            $(this).on('focus', function() {
                $(this).datetimepicker('show');
            });
            $(this).off('blur');
            $(this).on('blur', function() {
                $(this).datetimepicker('hide');
            });
            $(this).off('change.datetimepicker');
            $(this).on('change.datetimepicker', function(e){
                let id = create_id($(this).data('id'));
                if ($(this).data('type') == 'int') {
                    let values = $(this).val().split(':');
                    let minutes = (Number(values[0]) * 60) + (Number(values[1]));
                    $(id).val(minutes);
                } else {
                    $(id).val($(this).val());
                }
            });
        });
    }

    /*---- 時間選択フォームの初期化 ----*/

    if (type == 'all' || type == 'time-seconds') {
        $('.time-seconds-picker').each(function() {
            $(this).datetimepicker({
                datepicker: false,
                format: 'H:mm:ss',
                locale: 'ja',
                icons: {
                    time: 'far fa-clock',
                },
            });
            $(this).off('focus');
            $(this).on('focus', function() {
                $(this).datetimepicker('show');
            });
            $(this).off('blur');
            $(this).on('blur', function() {
                $(this).datetimepicker('hide');
            });
            $(this).off('change.datetimepicker');
            $(this).on('change.datetimepicker', function(e){
                let id = create_id($(this).data('id'));
                if ($(this).data('type') == 'int') {
                    let values = $(this).val().split(':');
                    let seconds = (Number(values[0]) * 60 * 60) + (Number(values[1]) * 60) + Number(values[2]);
                    $(id).val(seconds);
                } else {
                    $(id).val($(this).val());
                }
            });
        });
    }

    /*---- 日付選択フォームの初期化 ----*/

    if (type == 'all' || type == 'date') {
        $('.date-picker').each(function() {
            $(this).datetimepicker({
                format: 'YYYY/MM/DD',
                locale: 'ja',
                dayViewHeaderFormat: 'YYYY年 MM月',
                icons: {
                    time: 'far fa-clock',
                }
            });
            $(this).off('focus');
            $(this).on('focus', function() {
                // 値を取得
                let value = $(this).val();
                $(this).datetimepicker('show');
                // 元の値が空欄の場合はカレンダーの表示時に値を与えない
                if (value == '') {
                    $(this).val('')
                }
            });
            $(this).off('blur');
            $(this).on('blur', function() {
                $(this).datetimepicker('hide');
            });
            $(this).off('change.datetimepicker');
            $(this).on('change.datetimepicker', function(e){
                // 変更前の値がない場合かつ変更されている場合にイベントを発行
                if (e.oldDate != null && e.oldDate != e.date) {
                    $(this).trigger('change');
                }
            });
        });
    }

    /*---- 日時選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datetime') {
        $('.datetime-picker').each(function() {
            $(this).datetimepicker({
                format: 'YYYY/MM/DD HH:mm',
                locale: 'ja',
                dayViewHeaderFormat: 'YYYY年 MM月',
                icons: {
                    time: 'far fa-clock',
                }
            });
            $(this).off('focus');
            $(this).on('focus', function() {
                // 値を取得
                let value = $(this).val();
                $(this).datetimepicker('show');
                // 元の値が空欄の場合はカレンダーの表示時に値を与えない
                if (value == '') {
                    $(this).val('')
                }
            });
            $(this).off('blur');
            $(this).on('blur', function(e) {
                $(this).datetimepicker('hide');
            });
            $(this).off('change.datetimepicker');
            $(this).on('change.datetimepicker', function(e){
                // 変更前の値がない場合かつ変更されている場合にイベントを発行
                if (e.oldDate != null && e.oldDate != e.date) {
                    $(this).trigger('change');
                }
            });
        });
    }

    /*---- 日時（秒）選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datetime-seconds') {
        $('.datetime-seconds-picker').each(function() {
            $(this).datetimepicker({
                format: 'YYYY/MM/DD HH:mm:ss',
                locale: 'ja',
                dayViewHeaderFormat: 'YYYY年 MM月',
                icons: {
                    time: 'far fa-clock',
                }
            });
            $(this).off('focus');
            $(this).on('focus', function() {
                // 値を取得
                let value = $(this).val();
                $(this).datetimepicker('show');
                // 元の値が空欄の場合はカレンダーの表示時に値を与えない
                if (value == '') {
                    $(this).val('')
                }
            });
            $(this).off('blur');
            $(this).on('blur', function(e) {
                $(this).datetimepicker('hide');
            });
            $(this).off('change.datetimepicker');
            $(this).on('change.datetimepicker', function(e){
                // 変更前の値がない場合かつ変更されている場合にイベントを発行
                if (e.oldDate != null && e.oldDate != e.date) {
                    $(this).trigger('change');
                }
            });
        })
    }

    /*---- 月選択フォームの初期化 ----*/

    if (type == 'all' || type == 'month') {
        $('.month-picker').each(function() {
            var display_format = ($(this).data('display-format') ? $(this).data('display-format') : 'M');
            var name = $(this).data('target-name');
            if ($('input[name=' + name + ']').val() != '') {
                $(this).val(moment($('input[name=' + name + ']').val()).format(display_format));
            }
            $(this).off('changeDate');
            $(this).off('blur');
            $(this).datepicker({
                format: display_format.toLowerCase(),
                language: 'ja',
                autoclose: true,
                minViewMode: 'months',
            }).on('changeDate', function(e) {
                $('input[name=' + name + ']').val(moment(e.dates[0]).format('YYYY-MM'));
            }).on('blur', function(e) {
                if ($('input[name=' + name + ']').val() != '') {
                    $(this).val(moment($('input[name=' + name + ']').val()).format(display_format));
                }
            });
        });
        $('.month-picker-inputs a.back').off('click');
        $('.month-picker-inputs a.back').on('click', function () {
            month_picker_inputs_action(this, 'subtract');
        });
        $('.month-picker-inputs a.forward').off('click');
        $('.month-picker-inputs a.forward').on('click', function () {
            month_picker_inputs_action(this, 'add');
        });
        $('.month-picker-inputs a.current').off('click');
        $('.month-picker-inputs a.current').on('click', function () {
            month_picker_inputs_action(this, 'current');
        });
        function month_picker_inputs_action(elm, type)
        {
            let picker = $(elm).parent().find('.month-picker');
            let input = $(elm).parent().find('.month-picker-value');
            let display_format = ($(picker).data('display-format') ? $(picker).data('display-format') : 'M');
            let value = input.val();
            let update = moment();
            switch(type) {
                case 'subtract':
                    update = moment(value).subtract(1, 'months');
                    break;
                case 'add':
                    update = moment(value).add(1, 'months');
                    break;
            }
            $(elm).parent().find('.month-picker').val(update.format(display_format));
            $(elm).parent().find('.month-picker-value').val(update.format('YYYY-MM')).trigger('change');
        }
    }

    /*---- 年選択フォームの初期化 ----*/

    if (type == 'all' || type == 'year') {
        $('.year-picker').each(function() {
            var display_format = ($(this).data('display-format') ? $(this).data('display-format') : 'YYYY');
            var name = $(this).data('target-name');
            $(this).val(moment($('input[name=' + name + ']').val()).format(display_format));
            $(this).off('changeDate');
            $(this).off('blur');
            $(this).datepicker({
                format: display_format.toLowerCase(),
                language: 'ja',
                autoclose: true,
                minViewMode: 'years',
            }).on('changeDate', function(e) {
                $('input[name=' + name + ']').val(moment(e.dates[0]).format('YYYY'));
            }).on('blur', function(e) {
                $(this).val(moment($('input[name=' + name + ']').val()).format(display_format));
            });
        });
        $('.year-picker-inputs a.back').off('click');
        $('.year-picker-inputs a.back').on('click', function () {
            year_picker_inputs_action(this, 'subtract');
        });
        $('.year-picker-inputs a.forward').off('click');
        $('.year-picker-inputs a.forward').on('click', function () {
            year_picker_inputs_action(this, 'add');
        });
        $('.year-picker-inputs a.current').off('click');
        $('.year-picker-inputs a.current').on('click', function () {
            year_picker_inputs_action(this, 'current');
        });
        function year_picker_inputs_action(elm, type)
        {
            let picker = $(elm).parent().find('.year-picker');
            let input = $(elm).parent().find('.year-picker-value');
            let display_format = ($(picker).data('display-format') ? $(picker).data('display-format') : 'YYYY');
            let value = input.val();
            let update = moment();
            switch(type) {
                case 'subtract':
                    update = moment(value).subtract(1, 'years');
                    break;
                case 'add':
                    update = moment(value).add(1, 'years');
                    break;
            }
            $(elm).parent().find('.year-picker').val(update.format(display_format));
            $(elm).parent().find('.year-picker-value').val(update.format('YYYY')).trigger('change');
        }
    }


}
/**
 * スイッチフォームを初期化
 */
 window.initialize_switchflag = function() {
    // スイッチフォームを初期化
    $('.switch-flag').each(function() {
        switch_flag_action(this);
        $(document).off('change', '.switch-flag');
        $(document).on('change', '.switch-flag', function() {
            switch_flag_action(this);
        });
    });
    // スイッチ変更時の処理
    function switch_flag_action(elm) {
        let selector = $(elm).data('disabled');
        if ($(elm).prop('checked')) {
            $(selector).prop('disabled', false);
        } else {
            $(selector).prop('disabled', true);
        }
    }
}
/**
 * ソート可能リストの初期化
 */
 window.initialize_sortable = function() {
    // ソート可能なリストを初期化
    $('.sortable').sortable();
}
/**
 * 共通の禁止操作や初期化処理などを設定する。
 * @param {*} type all, tooltip, select2, multiselect, datepicker
 */
window.initialize = function (type = 'all') {

    /*---- ツールチップの有効化 ----*/

    if (type == 'all' || type == 'tooltip') {
        initialize_tooltip();
    }

    /*---- セレクト２を初期化 ----*/

    if (type == 'all' || type == 'select2') {
        initialize_select2();
    }

    /*---- マルチセレクトを初期化 ----*/

    if (type == 'all' || type == 'multiselect') {
        initialize_multiselect();
    }

    /*---- 時分選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('time-minutes');
    }

    /*---- 時間選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('time-seconds');
    }

    /*---- 日付選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('date');
    }

    /*---- 日時選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('datetime');
    }

    /*---- 日時（秒）選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('datetime-seconds');
    }

    /*---- 月選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('month');
    }

    /*---- 年選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('year');
    }

    /*---- スイッチフォームの初期化 ----*/

    if (type == 'all' || type == 'switchflag') {
        initialize_switchflag();
    }

    /*---- ソート可能リストの初期化 ----*/

    if (type == 'all' || type == 'sortable') {
        initialize_sortable();
    }

    /*---- その他の制御 ----*/

    if (type == 'all') {

        /*---- フォームの直接のサブミットを禁止 ----*/

        $(document).off('submit', '#search-form:not([data-force-send=true])');
        $(document).on('submit', '#search-form:not([data-force-send=true])', function() {
            return false;
        });

        /*---- モーダルの表示時にスクロールをトップに変更 ----*/

        $(document).off('shown.bs.modal', '.modal');
        $(document).on('show.bs.modal', '.modal', function (e) {
            $(this).find('.modal-body').scrollTop(0);
        });

        /*---- モーダルの重複表示 ----*/

        $(document).on('show.bs.modal', '.modal', function (e) {
            var currentModal = $(e.currentTarget);
            var zIndex = 1040 + (10 * $('.modal:visible').length);
            currentModal.css('z-index', zIndex);
            setTimeout(function() {
                $('.modal-backdrop')
                    .not('.modal-stack')
                    .css('z-index', zIndex - 1)
                    .addClass('modal-stack');
            }, 0);
        });
        // 親モーダルがスクロール不可になる問題を解決
        $(document).on('hidden.bs.modal', '.modal', function (e) {
            if ($('.modal.show').length) {  // 「ホップアップ表示後に画面遷移した（元の画面に戻る）際、スクロールすることができず、 毎回リロードしなければならない」不具合修正
                $('body').addClass('modal-open');
            }
        });

        /*---- フォーム操作時のフォーム外へのtab移動制限 ----*/

        $(document).off('keydown', 'form');
        $(document).on('keydown', 'form', function (e) {
            if (e.key == 'Tab') {
                let inputs = $(this).find('input:visible, textarea:visible, select:visible, button:visible').not(':disabled');
                let focused = $(this).find(':focus')[0];
                for(i = 0; i < inputs.length; i ++) {
                    if (focused == inputs[i]) {
                        break;
                    }
                }
                if (e.shiftKey) {
                    i --;
                } else {
                    i ++;
                }
                if (i >= inputs.length) {
                    e.preventDefault();
                    $(inputs[0]).trigger('focus');
                } else if (i < 0) {
                    e.preventDefault();
                    $(inputs[inputs.length - 1]).trigger('focus');
                }
            }
        });
    }
}
/**
 * 各ページ表示時に呼出
 */
$(function () {
    //コンテンツの読込
    load_contents();
});
