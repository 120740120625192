/**
 * セットアップページ用のスクリプト
 * @uses 管理画面と設計が異なるため共通部分は呼び出すもののbss独自イベントなどは使用しない。
 */
if (is_route('setup.index')) {

    $(function () {

        initialize();
        
        /*---- グループ会社選択時の処理 ----*/

        function setCompanySelect(elm, id) {
            // 変更が初期値の場合はリターン
            if (id == '') { return; }
            // 会社選択が存在していない場合はリターン
            if ($('select#setup-form-company_id').length == 0) {
                return;
            }
            // 送信先
            let action = $(elm).data('action');
            // 検索フォームを配列化
            let datas = [];
            // データを追加
            datas.push({'name' : 'holding_id', 'value' : id});
            // 送信
            _ajax.post(action, datas, function (results) {
                $('#setup-form-company_id').empty();
                $('#setup-form-company_id').select2({
                    width: "100%", 
                    data: results.options
                });
                initialize();
                $('.setup-company-select').removeClass('display-none');
                $('.setup-section-select, .setup-user-select').addClass('display-none');
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }

        // セレクト選択時
        $(document).on('select2:selecting', '#setup-form-holding_id', function(e) {
            setCompanySelect(this, e.params.args.data.id);
        });
        if ($('input[type=hidden]#setup-form-holding_id').val() != null) {
            setCompanySelect($('input[type=hidden]#setup-form-holding_id'), $('input[type=hidden]#setup-form-holding_id').val());
        }

        /*---- 会社選択時の処理 ----*/

        function setSectionSelect(elm, id) {
            // 変更が初期値の場合はリターン
            if (id == '') { return; }
            // 会社選択が存在していない場合はリターン
            if ($('select#setup-form-section_id').length == 0) {
                return;
            }
            // 送信先
            let action = $(elm).data('action');
            // 検索フォームを配列化
            let datas = [];
            // データを追加
            datas.push({'name' : 'company_id', 'value' : id});
            datas.push({'name' : 'nullable', 'value' : true});
            // 送信
            _ajax.post(action, datas, function (results) {
                $('#setup-form-section_id').empty();
                $('#setup-form-section_id').select2({
                    width: "100%", 
                    data: results.options
                });
                initialize();
                $('.setup-section-select').removeClass('display-none');
                $('.setup-user-select').addClass('display-none');
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }

        // セレクト選択時
        $(document).on('select2:selecting', '#setup-form-company_id', function(e) {
            setSectionSelect(this, e.params.args.data.id);
        });
        if ($('input[type=hidden]#setup-form-company_id').val() != null) {
            setSectionSelect($('input[type=hidden]#setup-form-company_id'), $('input[type=hidden]#setup-form-company_id').val());
        }

        /*---- 営業所選択時の処理 ----*/

        function setUserSelect(elm, id) {
            // 変更が初期値の場合はリターン
            if (id == '') { return; }
            // 会社選択が存在していない場合はリターン
            if ($('select#setup-form-user_id').length == 0) {
                return;
            }
            // 送信先
            let action = $(elm).data('action');
            // 検索フォームを配列化
            let datas = [];
            // データを追加
            datas.push({'name' : 'section_id', 'value' : id});
            datas.push({'name' : 'nullable', 'value' : true});
            // 送信
            _ajax.post(action, datas, function (results) {
                $('.setup-user-select').removeClass('display-none');
                if (results.options.length == 1) {
                    $('#user-select-none').removeClass('display-none');
                    $('#user-select').addClass('display-none');
                } else {
                    $('#setup-form-user_id').empty();
                    $('#setup-form-user_id').select2({
                        width: "100%", 
                        data: results.options
                    });
                    $('#user-select-none').addClass('display-none');
                    $('#user-select').removeClass('display-none');
                    initialize();
                }
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }

        // セレクト選択時
        $(document).on('select2:selecting', '#setup-form-section_id', function(e) {
            setUserSelect(this, e.params.args.data.id);
        });
        if ($('input[type=hidden]#setup-form-section_id').val() != null) {
            setUserSelect($('input[type=hidden]#setup-form-section_id'), $('input[type=hidden]#setup-form-section_id').val());
        }

    });
}