/**
 * ダッシュボードページ用のスクリプト
 */
if (is_route('dashboard.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        dashboard_event_set();
        dashboard_load_contents();
        dashboard_setteing_event_set();
    });
    // コンテンツの再読み込み後
    $(document).on('bss:reload', function() {
        dashboard_load_contents();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.dashboard_event_set = function () {

    /*---- タスク追加時のバブリングを禁止 ----*/

    // ボタンクリック時のバブリングを禁止
    $(document).on('click', '.information-list .list-group-item .btn[data-toggle=modal]', function (e) {
        e.preventDefault();
        e.stopPropagation();
    });

    /*---- 開いた通知の状態を保持 ----*/

    // 開いた通知の状態を保持
    $(document).on('click', '.information-button', function() {
        let type = $(this).data('type');
        _request.set_parameters([{'name': 'type', 'value': type}]);
    });

    /*---- 通知からの設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function() {
        // 設定モーダルを表示
        _modal.create(this, 'setting-modal', ['id', 'type', 'date', 'user_id']);
    });

    /*---- 通知からの画面遷移 ----*/

    // ページ移動
    $(document).on('click', '[data-location]', function() {
        location.href = $(this).data('location');
    });

    /*---- 通知からの設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        var modalId = $(this).data('save-modal');
        _modal.submit(this, 'save-modal', function(results) {
            if (modalId.indexOf('rollcall') >= 0) {
                $('[data-detail-modal=rollcall-detail-modal][data-date=' + moment(results.rollcall.rollcalled_at).format("YYYY-MM-DD") + ']').trigger('click');
            }
        }, function() {
            // ステップエラーを表示
            user_set_steper_error();
        });
    });

    /*---- 通知からの点呼設定画面の表示（点呼専用） ----*/

    // 設定表示時（点呼専用）
    $(document).on('click', '[data-detail-modal]', function() {
        // 設定モーダルを表示
        _modal.create(this, 'detail-modal', ['id', 'type', 'date', 'user_id']);
    });

    /*---- 通知からの削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function() {
        // 設定モーダルを表示
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 通知からの削除時 ----*/

    // 削除時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            $('.modal').each(function() {
                _modal.hide($(this));
            });
        });
    });

    /*---- 通知からタスクのドロップ時 ----*/

    // ドロップ時
    $(document).on('drop', '.task-list', function() {
        var dropId = create_id(event.dataTransfer.getData('id'));
        var modalId = $(dropId).data('information-to-task-modal');
        _modal.show(modalId);
    });

    /*---- タスク保存時 ----*/

    // 設定表示時
    $(document).on('click', '[data-task-modal]', function () {
        _modal.submit(this, 'task-modal');
    });

    /*---- タスク削除確認表示時 ----*/

    // 削除確認表示時
    $(document).on('click', '[data-task-confirm-modal]', function () {
        // 詳細モーダルを表示
        _modal.create(this, 'task-confirm-modal', ['id']);
    });

    /*---- タスク削除時 ----*/

    // タスク削除時
    $(document).on('click', '[data-task-delete-modal]', function () {
        _modal.submit(this, 'task-delete-modal', function(results) {
            _modal.hide('#task-setting-' + results.task.id);
        });
    });

    /*---- カレンダーの切り替え時 ----*/

    // カレンダーの切り替え時
    $(document).on('click', '.calendar-date', function() {
        var date = $(this).data('date');
        $('#search-form-current').val(date);
        // 非表示処置
        $('.calendar-date.active').removeClass('active');
        $('.calendar-date-detail').addClass('display-none');
        // 表示処理
        $(this).addClass('active');
        $('.calendar-date-detail[data-date=' + date + ']').removeClass('display-none');
    });

    /*---- タスクからカレンダーへのドロップ時 ----*/

    // スケジュールの登録時（ドロップ）
    $(document).on('drop', '.calendar-date', function(e) {
        let dropId = create_id(event.dataTransfer.getData('id'));
        $(this).data('id', $(dropId).data('id'));
        _modal.create(this, 'task-to-schedule-modal', ['id', 'date']);
        $(this).removeData('id');
    });


    /*---- スケジュール保存時 ----*/

    // スケジュールの登録時
    $(document).on('click', '[data-schedule-modal]', function () {
        var startedAt = moment($(create_id($(this).data('schedule-modal') + '-started_at')).val(), 'YYYY/MM/DD HH:mm');
        $('#search-form-current').val(startedAt.format('YYYY-MM-DD'));
        _modal.submit(this, 'schedule-modal');
    });

    /*---- スケジュール削除確認時 ----*/
    
    // スケジュールの削除確認モーダル
    $(document).on('click', '[data-schedule-confirm-modal]', function () {
        _modal.create(this, 'schedule-confirm-modal', ['id']);
    });

    /*---- スケジュール削除時 ----*/

    // スケジュール削除実行時
    $(document).on('click', '[data-schedule-delete-modal]', function () {
        _modal.submit(this, 'schedule-delete-modal', function(results) {
            _modal.hide('#schedule-setting-' + results.schedule.id);
        });
    });
}
/**
 * ページ読み込み時のイベント設定（再読み込みも実行）
 */
window.dashboard_load_contents = function() {

    /*---- お知らせリストの最終項目表示での読み込み処置 ----*/

    // スクロール時
    $('.tab-content').on('scroll', function() {
        var scroll = $(this).scrollTop();
        var height = $(this).outerHeight();
        var scrollHeight = $(this).get(0).scrollHeight;
        // スクロール位置が最終項目まで達した時（100は許容値）
        if (scroll + height + 100 > scrollHeight) {
            let list = $(this).find('.tab-panel.active .information-list');
            let paginator = $(list).find('.infomation-pagenation');
            if ($(paginator).length > 0 && $(paginator).data('loaded') == null) {
                $(paginator).show();
                $(paginator).data('loaded', 'loaded');
                dashboard_information_load(list);
            }
        }
    });

    /*---- お知らせのリスト分最初のページを読み込む ----*/

    // アクティブな項目を読み込み
    $('.dashboard-information .tab-panel.active .information-list').each(function() {
        // 非アクティブな項目はコールバックで読み込む
        dashboard_information_load(this, function() {
            $('.dashboard-information .tab-panel:not(.active) .information-list').each(function() {
                dashboard_information_load(this);
            });
        });
    });

    // 画面切り替え時
    $(document).on("click", 'input[name="screen_type"]', function(){
        var str = $(this).val();
        let newUrl = currentUrl = window.location.href;
        if (currentUrl.includes('bss/dashboard')) {
            if(str==1){
                newUrl = currentUrl.replace('bss/', '');
            }
        }
        else
        {
            if(str==2){
                newUrl = currentUrl.replace('/dashboard', '/bss/dashboard');
            }
        }
        if(newUrl != currentUrl){
            window.location.href = newUrl; 
        }
    });
}
/**
 * 設定モーダルのイベント設定
 */
window.dashboard_setteing_event_set = function () {
    user_setteing_event_set();
}
/**
 * お知らせの非同期取得
 * @param {*} selector 
 */
window.dashboard_information_load = function(selector, callback = null) {
    let datas = [];
    let action = $(selector).data('action');
    let type = $(selector).data('type');
    let page = $(selector).find('.infomation-pagenation').data('next');
    page = (page == null ? 1 : page);
    datas.push({
        'name': 'type', 
        'value': type 
    });
    datas.push({
        'name': 'page', 
        'value': page 
    });
    _ajax.background(action, datas, function (results) {
        $(selector).find('.infomation-pagenation').remove();
        $(selector).append(results);
        let total = ($($(selector).find('.infomation-pagenation')).length > 0 ? $($(selector).find('.infomation-pagenation')).data('total') : $(selector).find('li').length);
        let badge = create_id($(selector).data('badge_id'));
        if (total > 0) {
            $(badge).text(total)
            $(badge).toggleClass('display-none', false);
            $(selector).find('.information-not-found').toggleClass('display-none', true);
        } else {
            $(badge).toggleClass('display-none', true);
            $(selector).parent().find('.information-not-found').toggleClass('display-none', false);
        }
        $(selector).data('page', page);
        initialize();
        if (callback != null) {
            callback();
        }
    }, function(xhr, status, errorThrown) {
        _error.omitted(xhr, status, errorThrown);
    }, 'html');
}