/**
 * 完了メッセージの設定を行う
 * @param {*} response
 */
exports.set = function (response) {
    // 完了メッセージの表示
    $.each(response.messages, function(i, message) {
        toastr.success(message);
    });
};

/**
 * 注意メッセージの設定を行う
 * @param {*} response
 */
exports.warningSet = function (response) {
    // 注意メッセージの表示
    $.each(response.warnings, function(i, warning) {
        toastr.warning(warning);
    });
};