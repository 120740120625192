export function setFileDownloadEvent(eventKey, getActionAndDataCallback, failCallback = null) {
    $(document).on('click', eventKey, function () {
        let action = '';
        let data = {};
        if (typeof getActionAndDataCallback === 'function') {
            [action, data] = getActionAndDataCallback(this);
        }

        if (failCallback === null) {
            failCallback =  function (xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            }
        }
        
        // Blobでダウンロード
        _ajax.blob(action, data, function (blob, status, xhr) {
            let fileName = decodeURIComponent(xhr.getResponseHeader('filename'));
            // aタグを経由してダウンロード
            var a = document.createElement("a");
            var blobUrl = window.URL.createObjectURL(new Blob([blob], {
                type: blob.type
            }));
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = blobUrl;
            a.download = fileName;
            a.click();
        }, failCallback);
    });
}