/**
 * モーダル画面を表示する
 * @param {*} modalId
 */
exports.show = function (modalId) {
    // モーダルを開く
    $(modalId).modal('show');
};
/**
 * モーダル画面を閉じる
 * @param {*} modalId
 */
exports.hide = function(modalId) {
    // モーダルを閉じる
    $(modalId).modal('hide');
};
/**
 * モーダル画面を作成して表示する
 * @param {*} modalId
 */
exports.create = function(elm, modalIdDataSelector, keys, isExtendQuery = false, callback = null, actionDataSelector = 'action',extendData = {}) {
    if (window.getSelection().type === 'Range') {
        return; // 画面コピーした際に詳細画面が立ちあがらないように
    }

    // postデータ
    var datas = [];
    var modalId = create_id($(elm).data(modalIdDataSelector));
    var action = $(elm).data(actionDataSelector);
    $.each(keys, function(i, key) {
        if ($(elm).data(key) != null) {
            datas.push({'name' : key, 'value' : $(elm).data(key)});
        }
    });
    if (isExtendQuery) {
        action = extend_url_query(action);
    }

    if (Object.keys(extendData).length > 0) {
        for (const name in extendData) {
            if (extendData.hasOwnProperty(name)) {
                const value = extendData[name];
                datas.push({name, value});
            }
        }
    }


    // モーダルのコンテンツを空に
    $(modalId + ' .modal-content').empty();
    // 送信
    _ajax.post(action, datas, function (results) {
        $(modalId + ' .modal-content').html(results);
        initialize();
        if (callback != null) {
            callback();
        }
        _modal.show(modalId);
    }, function(xhr, status, errorThrown) {
        _error.omitted(xhr, status, errorThrown);
    }, 'html');
};
/**
 * モーダル画面を作成して表示する
 * @param {*} modalId
 */
 exports.create_with_data = function(elm, modalIdDataSelector, datas, isExtendQuery = false, callback = null, actionDataSelector = 'action') {
    // postデータ
     var modalId = create_id($(elm).data(modalIdDataSelector));
     var action = $(elm).data(actionDataSelector);
    if (isExtendQuery) {
        action = extend_url_query(action);
    }
    // モーダルのコンテンツを空に
    $(modalId + ' .modal-content').empty();
    // 送信
    _ajax.post(action, datas, function (results) {
        $(modalId + ' .modal-content').html(results);
        initialize();
        if (callback != null) {
            callback();
        }
        _modal.show(modalId);
    }, function(xhr, status, errorThrown){
        _error.omitted(xhr, status, errorThrown);
    }, 'html');
};
/**
 * モーダルコンテンツを更新する
 * @param {*} modalId
 * @param {*} action
 * @param {*} datas
 * @param {*} callback
 */
exports.update = function(modalId, action, datas, callback = null) {
    modalId = create_id(modalId);
    // 送信
    _ajax.post(action, datas, function (results) {
        $(modalId + ' .modal-content').html(results);
        initialize();
        if (callback != null) {
            callback();
        }
        _modal.show(modalId);
    }, function(xhr, status, errorThrown){
        _error.omitted(xhr, status, errorThrown);
    }, 'html');
}
/**
 * モーダルのサブミット処理
 * @param {*} elm
 * @param {*} modalIdDataSelector
 * @param {*} loadContentCallback
 * @param {*} errorCallback
 * @param {*} formSelectorSuffix
 */
exports.submit = function(elm, modalIdDataSelector, loadContentCallback = null, errorCallback = null, formSelectorSuffix = '-form', isShowToastErrors=false) {
    var modalId = create_id($(elm).data(modalIdDataSelector));
    var formId = modalId + formSelectorSuffix;
    // 送信前にエラーをリセット
    _error.reset()
    // post送信
    _ajax.submit(formId, function (results) {
        // リダイレクト指定時はリダイレクト
        if (results.redirect != null) {
            location.href = results.redirect;
            return;
        }
        // 完了メッセージをセットする
        _complete.set(results);
        // 注意メッセージをセットする
        _complete.warningSet(results);
        // モーダルを閉じる
        _modal.hide(modalId);
        // コンテンツの読込
        if (loadContentCallback != null) {
            load_contents(function() {
                loadContentCallback(results);
            });
        } else {
            load_contents();
        }
    }, function (xhr, status, errorThrown) {
        // エラーをセットする
        _error.set(modalId, xhr, status, errorThrown, true, isShowToastErrors);
        if (errorCallback != null) {
            errorCallback();
        }
    });
};
