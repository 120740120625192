/**
 * COTOS管理ページ用のスクリプト
 */
if (is_route('cotos.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function () {
        cotos_event_set();
    });
 }

 /**
 * ページ読み込み時のイベント設定
 */
window.cotos_event_set = function () {
    // ボタンクリック時のバブリングを禁止
    $(document).on('click', '.product_license_file_link', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['id']);
    });
    
    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {

        _modal.create(this, 'setting-modal', ['id']);
    });
    
    /*---- 設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        if(document.getElementById('cotos-setting-modal-status_0').checked&& !confirm("完了処理を行います。成功したらデータ変更できません、よろしいでしょうか。"))
        {
          return;
        }
        _modal.submit(this, 'save-modal', function(results) {
            $('[data-detail-modal=product-detail-modal][data-id=' + results.cotos.id + ']').trigger('click');
        },null,'-form',true);
    });
    
    // 都道府県変更時
    $(document).on('change', '#area', function (e) {
        // 選択した都道府県を取得
        let prefecture = document.getElementById('area').value;
        let action = 'cotos/prefecture';
        let datas = {'area': prefecture}
        // 送信
        _ajax.post(action, datas, function (results) {
            let area_info = document.getElementById('area_info');
            document.getElementById('manager_name').textContent = results.company_charge_name;
            document.getElementById('manager_company').textContent = results.company_name;
            document.getElementById('manager_depart').textContent = results.depart_name;
            document.getElementById('manager_tel').textContent = results.phone_number;
            document.getElementById('manager_email').textContent = results.emails;
            // エリアマネージャ情報の表示・非表示
            if (results.id != null) {
                area_info.classList.remove('area_info_hidden');
                area_info.classList.add('area_info');
            } else {
                area_info.classList.remove('area_info');
                area_info.classList.add('area_info_hidden');
            }
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        }, 'json');
    });

    window.checkVendorSubmissionRow = function () {
        let row = document.getElementById('row-of-doc3');
        let tds = row.getElementsByTagName('td');
        let isEmpty = Array.from(tds).slice(1).every(function (td) {
            return td.textContent.trim() === '';
        });
        return !isEmpty;
    };

    
    /*---- Excel出力ボタン押下時 ----*/
    // 入出庫履歴Excel出力時
    $(document).on('click', '#search-form-action_excel_download', function () {
        if (!window.checkVendorSubmissionRow()) {
            alert('ベンダー送信用申込書のファイルが存在しません。');
            return;
        }

        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#cotos-detail-modal-form').serializeArray();
        // BlobでExcel出力を別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            let link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            const rjNumber = document.getElementById("cotos-detail-modal-rj_number").value;
            const endHoldingName = document.getElementById("select2-cotos-detail-modal-end_holding_id-container").innerText;
            const filename = `COTOS事務局通知書向け通知書${rjNumber ? `_${rjNumber}` : ''}${endHoldingName ? `_${endHoldingName}` : ''}`;
            link.setAttribute("download", filename);
            link.click();
            URL.revokeObjectURL(link.href);
            link = null;
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    });

    $(document).on('click', '.file-download', function () {
        action = $(this).data('action');
        datas = [
            {name: "id", value: $(this).data('id')}, 
            {name: "path", value: $(this).data('path')},
            {name: "document", value: $(this).data('document')}
        ];
        _ajax.blob(action, datas, function (blob, status, xhr) {
            let fileName = decodeURIComponent(xhr.getResponseHeader('filename'));
            // aタグを経由してダウンロード
            var a = document.createElement("a");
            var blobUrl = window.URL.createObjectURL(new Blob([blob], {
                type: blob.type
            }));
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = blobUrl;
            a.download = fileName;
            a.click();
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });
    
    // ユーザー管理番号でエンドユーザーグループ会社を自動選択する
    $(document).on('blur', '#cotos-setting-modal-end_holding_code', function () {
        let end_holding_code = document.getElementById('cotos-setting-modal-end_holding_code').value;
        let action = 'cotos/end_holding';
        let datas = { 'end_holding_code': end_holding_code }
         // 送信
        _ajax.post(action, datas, function (results) {
            if (results.id != null) {
                document.getElementById('cotos-setting-modal-end_holding_name').textContent = results.name;
                document.getElementById('cotos-setting-modal-end_holding_id').value = results.id;
                if (results.area_id && results.area_id > 0) {
                    $('#area').val(results.area_id).trigger('change');
                }
            } else {
                document.getElementById('cotos-setting-modal-end_holding_name').innerHTML = '<span class="text-danger">登録されているグループ会社が見つかりません</span>';
                document.getElementById('cotos-setting-modal-end_holding_id').value = null;
            }
        });
    });

    $('#cotos-setting-modal').on('hidden.bs.modal', function () {
         $( '#cotos-setting-modal .modal-content').empty();
    });

    // メール詳細
    $(document).on('click', '[data-mail-detail-modal]', function () {
        _modal.create(this, 'mail-detail-modal', ['id']);
    });

    // メール編集
    $(document).on('click', '[data-mail-setting-modal]', function () {
        if (!window.checkVendorSubmissionRow()) {
            alert('ベンダー送信用申込書のファイルが存在しません。');
            return;
        }
        // エラーリセット
        _error.reset()

        // formをデータ化
        let datas = $('#cotos-detail-modal-form').serializeArray();
        // データ追加
        let modalId = create_id($(this).data('mail-setting-modal'));
        let action = $(this).data('action');
        // 送信
        _ajax.post(action, datas, function (results) {
            // モーダルのコンテンツを空にする
            $(modalId + ' .modal-content').empty();
            // モーダルにコンテンツを追加する
            $(modalId + ' .modal-content').html(results);
            initialize();
            _modal.show(modalId);
        }, function (xhr, status, errorThrown) {
            // エラーをセットする
            _error.set('#cotos-detail-modal', xhr, status, errorThrown, true, true);
        }, 'html');
    });

    // メール送信
    $(document).on('click', '[data-mail-send]', function () {
        _modal.submit(this, 'mail-send', function (results) {
        }, function (xhr, status, errorThrown) {
        });
    });

    // メール編集画面用スクリプト読込
    require('../util/mail');

    require('../common/file');

}

 /*---- 契約日変更時 ----*/

    // 契約日変更画面表示時
    $(document).on('click', '[data-contracted_at_change-modal]', function () {
        _modal.create(this, 'contracted_at_change-modal', ['id']);
    });
    
    // 契約日更新時
    $(document).on('click', '[data-contracted_at_update-modal]', function () {
        _modal.submit(this, 'contracted_at_update-modal', null,null,'-form',true);
    });
    

