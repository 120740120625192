/**
 * サイドバーの表示・非表示の処理を行う。
 */
$(function () {
    // グループ会社の選択時
    $(document).on('click', '.dropdown-menu[aria-labelledby=user-holding-config] .dropdown-item', function() {
        // データの取得
        let id = $(this).data('holding_id');
        let action = $(this).parents('.dropdown-menu').data('action');
        let is_extend = $(this).parents('.dropdown-menu').data('is-extend');
        let excution = $(this).parents('.dropdown-menu').data('excution');
        let excutionData = (excution != null ? excution.split(',') : []);
        excutionData.push('company_id', 'section_id', 'page');
        // URLを作成
        action = _request.create_affiliation_url(action, is_extend, [{'name': 'holding_id', 'value': id}], excutionData);
        // リダイレクト
        location.href = action;
    });
    // 会社の選択時
    $(document).on('click', '.dropdown-menu[aria-labelledby=user-company-config] .dropdown-item', function() {
        // データの取得
        let id = $(this).data('company_id');
        let action = $(this).parents('.dropdown-menu').data('action');
        let is_extend = $(this).parents('.dropdown-menu').data('is-extend');
        let excution = $(this).parents('.dropdown-menu').data('excution');
        let excutionData = (excution != null ? excution.split(',') : []);
        excutionData.push('section_id', 'page');
        // URLを作成
        action = _request.create_affiliation_url(action, is_extend, [{'name': 'company_id', 'value': id}], excutionData);
        // リダイレクト
        location.href = action;
    });
    // 営業所の選択時
    $(document).on('click', '.dropdown-menu[aria-labelledby=user-section-config] .dropdown-item', function() {
        // データの取得
        let id = $(this).data('section_id');
        let action = $(this).parents('.dropdown-menu').data('action');
        let is_extend = $(this).parents('.dropdown-menu').data('is-extend');
        let excution = $(this).parents('.dropdown-menu').data('excution');
        let excutionData = (excution != null ? excution.split(',') : []);
        excutionData.push('page');
        // URLを作成
        action = _request.create_affiliation_url(action, is_extend, [{'name': 'section_id', 'value': id}], excutionData);
        // リダイレクト
        location.href = action;
    });
});