/**
 * インプットの共通イベント処理を設定する
 */
$(function () {

    // 半角数字入力
    $(document).on('compositionend', '.harf-char', function(e) {
        var half = $(this).val().replace(/[！-～]/g, function(str) {
            // 文字コードをシフト
            return String.fromCharCode(str.charCodeAt(0) - 0xFEE0);
        });
        half.replace(/”/g, "\"")
        .replace(/’/g, "'")
        .replace(/‘/g, "`")
        .replace(/￥/g, "\\")
        .replace(/　/g, " ")
        .replace(/〜/g, "~");
        $(this).val(half);
    });

    // 郵便番号
    $(document).on('click', '.zipcode-button', function () {
        let zipcodeId = create_id($(this).data('zipcode-button'));
        let prefecture = $('[data-type=prefecture][data-zipcode=' + $(this).data('zipcode-button') + ']');
        let municipality = $('[data-type=municipality][data-zipcode=' + $(this).data('zipcode-button') + ']');
        let address = $('[data-type=address][data-zipcode=' + $(this).data('zipcode-button') + ']');
        if ($(prefecture).length > 0 || $(municipality).length > 0 || $(address).length > 0) {
            let zip = $(zipcodeId).val().replace(/[^0-9]/g, '');
            new YubinBango.Core(zip, function(addr) {
                $(prefecture).val(addr.region);
                $(municipality).val(addr.locality);
                $(address).val(addr.street);
            });
        }
    });

    // アイコンボタン
    $(document).on('click', '.icon-button-inputs .icon-button:not(.dropdown-toggle)', function () {
        let name = $(this).data('name');
        let value = $(this).data('value');
        $('input[name=' + name + ']').val(value);
        $('input[name=' + name + ']').trigger('change');
    });

    // イメージボタン
    $(document).on('click', '.image-button-inputs .image-button:not(.dropdown-toggle)', function () {
        let input = $(this).parents('.image-button-inputs').find('.image-button-value');
        let value = $(input).data('value');
        let isActive = ($(input).val().length > 0);
        if (isActive) {
            $(input).val('');
        } else {
            $(input).val(value);
        }
        $(this).toggleClass('actice', !isActive);
        $(input).trigger('change');
    });

    // 数入力
    $(document).on('blur', '.number-inputs .number-input', function () {
        let input = $(this).parents('.number-inputs').find('.number-input-value');
        let suffix = $(this).data('suffix');
        if ($(this).val() != '') {
            let number = $(this).val().replace(/[^0-9]/g, '');
            $(this).attr('type', 'text');
            $(input).val(number);
            $(this).val(number + suffix);
            $(input).trigger('change');
        } else {
            $(this).attr('type', 'text');
            $(input).val('');
        }
    });
    $(document).on('focus', '.number-inputs .number-input', function () {
        let input = $(this).parents('.number-inputs').find('.number-input-value');
        let number = $(input).val();
        $(this).val(number);
        $(this).attr('type', 'number');
    });

    // 数入力(小数点あり)
    $(document).on('blur', '.float-inputs .float-input', function () {
        let input = $(this).parents('.float-inputs').find('.float-input-value');
        let suffix = $(this).data('suffix');
        if ($(this).val() == '0') {
            $(this).val('');
        }
        if ($(this).val() != '') {
            // let float = $(this).val().replace(/^([0-9]\d*|0)(\.\d+)?$/g, '');
            let float = $(this).val().replace(/^([0-9]{0,2}|0)(\.[0-9])?$/, '');
            $(this).attr('type', 'text');
            $(input).val(float);
            $(this).val(float + suffix);
            $(input).trigger('change');
        } else {
            $(this).attr('type', 'text');
            $(input).val('');
        }
    });
    $(document).on('focus', '.float-inputs .float-input', function () {
        let input = $(this).parents('.float-inputs').find('.float-input-value');
        let float = $(input).val();
        $(this).val(float);
        $(this).attr('type', 'float');
    });

    // 日数入力
    $(document).on('blur', '.day-inputs .day-input', function () {
        let input = $(this).parents('.day-inputs').find('.day-input-value');
        let suffix = $(this).data('suffix');
        if ($(this).val() != '') {
            let day = $(this).val().replace(/[^0-9]/g, '');
            $(this).attr('type', 'text');
            $(input).val(day);
            $(this).val(day + suffix);
            $(input).trigger('change');
        } else {
            $(this).attr('type', 'text');
            $(input).val('');
        }
    });
    $(document).on('focus', '.day-inputs .day-input', function () {
        let input = $(this).parents('.day-inputs').find('.day-input-value');
        let day = $(input).val();
        $(this).val(day);
        $(this).attr('type', 'number');
    });

    // 時間入力
    $(document).on('blur', '.hour-inputs .hour-input', function () {
        let input = $(this).parents('.hour-inputs').find('.hour-input-value');
        let suffix = $(this).data('suffix');
        let valueFormat = $(this).data('value-format');
        if ($(this).val() != '') {
            let hour = $(this).val().replace(/[^0-9]/g, '');
            hour = value = (hour.length > 0 ? hour : '0');
            if (valueFormat == 'minutes') {
                value = _format.toMinutes(value);
            } else if (valueFormat == 'seconds') {
                value = _format.toSeconds(value, false);
            }
            $(input).val(value);
            $(this).attr('type', 'text');
            $(this).val(hour + suffix);
            $(input).trigger('change');
        } else {
            $(this).attr('type', 'text');
            $(input).val('');
        }
    });
    $(document).on('focus', '.hour-inputs .hour-input', function () {
        let input = $(this).parents('.hour-inputs').find('.hour-input-value');
        let valueFormat = $(this).data('value-format');
        hour = value = $(input).val();
        if (valueFormat == 'minutes') {
            hour = _format.toHours(hour);
        } else if (valueFormat == 'seconds') {
            hour = _format.toHours(hour, false);
        }
        $(this).val(hour);
        $(this).attr('type', 'number');
    });

    // 分入力
    $(document).on('blur', '.minute-inputs .minute-input', function () {
        let input = $(this).parents('.minute-inputs').find('.minute-input-value');
        let suffix = $(this).data('suffix');
        let valueFormat = $(this).data('value-format');
        let minute = $(this).val().replace(/[^0-9]/g, '');
        $(this).attr('type', 'text');
        minute = value = (minute.length > 0 ? minute : '0');
        if (valueFormat == 'hours') {
            value = _format.toHours(value);
        } else if (valueFormat == 'seconds') {
            value = _format.toSeconds(value, false);
        }
        $(input).val(value);
        $(this).val(minute + suffix);
        $(input).trigger('change');
    });
    $(document).on('focus', '.minute-inputs .minute-input', function () {
        let input = $(this).parents('.minute-inputs').find('.minute-input-value');
        let valueFormat = $(this).data('value-format');
        minute = value = $(input).val();
        if (valueFormat == 'hours') {
            minute = _format.toMinutes(minute);
        } else if (valueFormat == 'seconds') {
            minute = _format.toMinutes(minute, false);
        }
        $(this).val(minute);
        $(this).attr('type', 'number');
    });
});