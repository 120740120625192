/**
 * Post送信処置
 * @param {*} selector 
 * @param {*} done 
 * @param {*} fail 
 */
exports.post = function (action, data, done, fail, type = 'json') {
    module.exports.send({
        type: 'post',
        url: action, 
        dataType: type, 
        data: data, 
    }, done, fail);
};
/**
 * Post送信処置（オーバーレイ非表示）
 * @param {*} selector 
 * @param {*} done 
 * @param {*} fail 
 */
 exports.background = function (action, data, done, fail, type = 'json') {
    module.exports.send({
        type: 'post',
        url: action, 
        dataType: type, 
        data: data, 
        global: false, // グローバルな設定を読み込まない
    }, done, fail);
};
/**
 * Post送信処置（レスポンスをblobで受け取る）
 * @param {*} action 
 * @param {*} data 
 * @param {*} done 
 * @param {*} fail 
 */
exports.blob = function (action, data, done, fail) {
    module.exports.send({
        url: action,
        method: 'post',
        data: data,
        xhr: function() {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob'
            return xhr;
        } 
    }, done, fail);
};
/**
 * File送信処置
 * @param {*} selector 
 * @param {*} done 
 * @param {*} fail 
 */
 exports.file = function (action, data, done, fail, type = 'json') {
    module.exports.send({
        type: 'post',
        url: action, 
        dataType: type, 
        data: data, 
        cache : false,
        contentType : false,
        processData : false,
    }, done, fail);
};
/**
 * get送信処置
 * @param {*} selector 
 * @param {*} done 
 * @param {*} fail 
 */
exports.get = function (action, done, fail, type = 'json') {
    module.exports.send({
        type: 'get',
        url: action, 
        dataType: type, 
    }, done, fail);
};
/**
 * formのサブミット処置
 * @param {*} selector 
 * @param {*} done 
 * @param {*} fail 
 */
exports.submit = function (selector, done, fail, type = 'json') {
    module.exports.send({
        type: $(selector).attr('method'),
        url: $(selector).attr('action'), 
        dataType: type, 
        data: $(selector).serialize()
    }, done, fail);
};
/**
 * formの確認用のサブミット処置
 * @param {*} selector 
 * @param {*} done 
 * @param {*} fail 
 */
 exports.confirm_submit = function (selector, action, done, fail, type = 'json') {
    module.exports.send({
        type: $(selector).attr('method'),
        url: action, 
        dataType: type, 
        data: $(selector).serialize()
    }, done, fail);
};
/**
 * ajax送信処理
 * @param {*} params 
 * @param {*} done 
 * @param {*} fail 
 */
exports.send = function(params, done, fail) {
    $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') } });
    $.ajax(params).done(done).fail(fail);
};