/**
 * ランニング注文ページ用のスクリプト
 */
if (is_route('bss_yearly_running_order.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        bss_yearly_running_order_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.bss_yearly_running_order_event_set = function () {

    // 設定表示
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id']);
    });

    // 設定保存
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function (results) {
        }, function () {
        });
    });

    // リスト更新
    $(document).on('click', '[data-update_list]', function () {
        let action = $(this).data('action');
        let month = $('#search-form-month').attr('value');
        _ajax.post(action, { 'month': month }, function (results) {
            load_contents();
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    });

    // メール詳細
    $(document).on('click', '[data-mail-detail-modal]', function () {
        _modal.create(this, 'mail-detail-modal', ['month', 'end_holding_id',  'index']);
    });

    // メール編集
    $(document).on('click', '[data-mail-setting-modal]', function () {
        _modal.create(this, 'mail-setting-modal', ['month', 'end_holding_id','mail_seq']);
    });

    // toアドレス追加
    $(document).on('click', '[data-append-to_emails]', function () {
        toIndex++;
        let emailInput = getInputEmail('yearly_running_order-mail-setting-modal-to_emails_' + toIndex + '_address', 'to_emails[' + toIndex + '][address]');
        $('#to_emails-wrapper').append(emailInput);
    });

    // ccアドレス追加
    $(document).on('click', '[data-append-cc_emails]', function () {
        ccIndex++;
        let emailInput = getInputEmail('yearly_running_order-mail-setting-modal-cc_emails_' + ccIndex + '_address', 'cc_emails[' + ccIndex + '][address]');
        $('#cc_emails-wrapper').append(emailInput);
    });

    // アドレス削除
    $(document).on('click', '[data-remove]', function () {
        $(this).closest('.email-address').remove();
    });

    function getInputEmail(id, name) {
        return $(`<div class="email-address">
                    <input type="email" id="${id}" class="multi-error" name="${name}" />
                    <span class="remove-button" data-remove>削除</span>
                    <div class="" id="${id}-errors">
                        <span class="invalid-feedback copy" role="alert"></span>
                    </div>
                </div>
                `);
    }

    // メール送信
    $(document).on('click', '[data-mail-send]', function () {
        _modal.submit(this, 'mail-send', function (results) {
        }, function (xhr, status, errorThrown) {
        });
    });

    // ファイルアップロード表示時
    $(document).on('click', '#search-form-action_upload', function () {
        // 初期化処理
        $('#yearly_running_order-upload-setting-modal-file-submit-message').addClass('display-none');
        $('#yearly_running_order-upload-setting-modal-file-submit-message').empty();
        $('#yearly_running_order-upload-setting-modal-file-error').addClass('display-none');
        $('#yearly_running_order-upload-setting-modal-file-error').empty();
        $('#yearly_running_order-upload-setting-modal-file').val('');
    });

    /*---- ファイルアップロードドラッグ＆ドロップ ----*/

    // （エリア以外でドロッグの場合）ブラウザのデフォル動作を回避①
    document.addEventListener('drop', function (e) {
        e.preventDefault()
    })
    // （エリア以外でドロッグの場合）ブラウザのデフォル動作を回避②
    document.addEventListener('dragover', function (e) {
        e.preventDefault()
    })

    // ドラッグしたままエリアに乗った＆外れたとき
    $(document).on('dragover', '#yearly_running_order-upload-setting-modal-drop_area', function (event) {
        event.preventDefault();
        $(this).css("background-color", "#999999");
    });
    $(document).on('dragleave', '#yearly_running_order-upload-setting-modal-drop_area', function (event) {
        event.preventDefault();
        $(this).css("background-color", "transparent");
    });
    // ドラッグした時
    $(document).on('drop', '#yearly_running_order-upload-setting-modal-drop_area', function (event) {
        var uploadableType = [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];
        let org_e = event;
        if (event.originalEvent)
        {
            org_e = event.originalEvent;
        }

        org_e.preventDefault();
        var uploadable = true;
        for (const element of org_e.dataTransfer.files)
        {
            if (!uploadableType.includes(element.type))
            {
                alert('Excelファイル以外はアップロードできません。');
                uploadable = false;
                break;
            }
        }
        if (uploadable)
        {
            $('#yearly_running_order-upload-setting-modal-file')[0].files = org_e.dataTransfer.files;
            $('#yearly_running_order-upload-setting-modal-file').change();
        }
        $(this).css("background-color", "transparent");
    });

    // ファイルアップロード確認時
    $(document).on('change', '#yearly_running_order-upload-setting-modal-file', function () {
        let modalId = '#yearly_running_order-upload-setting-modal';
        let formId = modalId + '-form';
        let action = $(formId).data('confirm-action');

        $("[data-upload-save-modal]").prop("disabled", true);
        $(modalId + '-error').addClass('display-none');
        $(modalId + '-error').empty();
        $(modalId + '-submit-message').addClass('display-none');
        $(modalId + '-submit-message').empty();

        let file = this.files[0]; //
        let maxSize = 5 * 1024 * 1024; //
        if (file.size > maxSize) {
            toastr.warning('ファイルサイズは5MB以下でお願いいたします。');
            return; //
        }

        let datas = new FormData($(formId).get(0));
        let month = $('#search-form-month').attr('value');
        datas.append('month', month);
        _ajax.file(action, datas, function (results) {
            $(modalId + '-file-submit-message').removeClass('display-none');
            $(modalId + '-file-submit-message').text(results.messages[0]);
            $("[data-upload-save-modal]").prop("disabled", false);
        }, function (xhr, status, errorThrown) {
            if (xhr.status === 502) {
                toastr.warning('ファイルが大きすぎる可能性があり、リクエストがタイムアウトしました。小さいファイルを試してください。');
                return;
            }
            _error.omitted(xhr, status, errorThrown);
            $(this).val('');
            // フォームにエラーメッセージを配置
            $.each(xhr.responseJSON.errors, function (name, message) {
                $(modalId + '-file-error').removeClass('display-none');
                $(modalId + '-file-error').append($('<li></li>').text(message));
            });
        });
    });

    // ファイル保存時
    $(document).on('click', '[data-upload-save-modal]', function () {
        let modalId = '#yearly_running_order-upload-setting-modal';
        let formId = modalId + '-form';
        let action = $(formId).attr('action');

        // エラーをリセット
        _error.reset()

        let datas = new FormData($(formId).get(0));
        let month = $('#search-form-month').attr('value');
        datas.append('month', month);
        _ajax.file(action, datas, function (results) {
            // 完了メッセージをセットする
            _complete.set(results);
            // モーダルを閉じる
            _modal.hide(modalId);
            // コンテンツの読込
            load_contents();
        }, function (xhr, status, errorThrown) {
            // エラーをセットする
            _error.omitted(xhr, status, errorThrown);
        });
    });

    // エリア（複数可）絞り込み決まるとき
    $(document).on('click', '#running_order-submit_area', function () {
       // console.log('submit_area: ');
        load_contents(null, false);
    });

}
