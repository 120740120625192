/**
 * 営業所設定ページ用のスクリプト
 */
 if (is_route('end_section.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        end_section_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.end_section_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id']);
    });

    // 配送先グループ会社変更時
    $(document).on('change', 'select.end_holding-select', function () {
        const selectedOption = $(this).find('option:selected');
        let systemCategoryText = selectedOption.data('system_category_text');
        if (!systemCategoryText) {
            systemCategoryText = '配送先グループ会社を選択してください';
        }
        $('#system_category').text(systemCategoryText);
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {
        });
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal',
        function(results) {
            // ツールバーの更新が必要なのでリロードする
            location.reload();
        },null,'-form',true
        );
    });

    /*---- CSVダウンロード時 ----*/

    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#csv-dl-list').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });
}
