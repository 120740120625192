import { setupFileUpload } from "../util/upload";

/**
 * ランニング注文ページ用のスクリプト
 */
if (is_route('yearly_running_order.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        yearly_running_order_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.yearly_running_order_event_set = function () {

    // 設定表示
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id']);
    });

    // 設定保存
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function (results) {
        }, function () {
        });
    });

    // リスト更新
    $(document).on('click', '[data-update_list]', function () {
        let action = $(this).data('action');
        let month = $('#search-form-month').attr('value');
        _ajax.post(action, { 'month': month }, function (results) {
            load_contents();
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    });

    // メール詳細
    $(document).on('click', '[data-mail-detail-modal]', function () {
        _modal.create(this, 'mail-detail-modal', ['month', 'end_holding_id',  'index']);
    });

    // メール編集
    $(document).on('click', '[data-mail-setting-modal]', function () {
        _modal.create(this, 'mail-setting-modal', ['month', 'end_holding_id','mail_seq']);
    });

    // toアドレス追加
    $(document).on('click', '[data-append-to_emails]', function () {
        toIndex++;
        let emailInput = getInputEmail('yearly_running_order-mail-setting-modal-to_emails_' + toIndex + '_address', 'to_emails[' + toIndex + '][address]');
        $('#to_emails-wrapper').append(emailInput);
    });

    // ccアドレス追加
    $(document).on('click', '[data-append-cc_emails]', function () {
        ccIndex++;
        let emailInput = getInputEmail('yearly_running_order-mail-setting-modal-cc_emails_' + ccIndex + '_address', 'cc_emails[' + ccIndex + '][address]');
        $('#cc_emails-wrapper').append(emailInput);
    });

    // アドレス削除
    $(document).on('click', '[data-remove]', function () {
        $(this).closest('.email-address').remove();
    });

    function getInputEmail(id, name) {
        return $(`<div class="email-address">
                    <input type="email" id="${id}" class="multi-error" name="${name}" />
                    <span class="remove-button" data-remove>削除</span>
                    <div class="" id="${id}-errors">
                        <span class="invalid-feedback copy" role="alert"></span>
                    </div>
                </div>
                `);
    }

    // メール送信
    $(document).on('click', '[data-mail-send]', function () {
        _modal.submit(this, 'mail-send', function (results) {
        }, function (xhr, status, errorThrown) {
        });
    });

    // 注文書データアップロード
    let month = $('#search-form-month').attr('value');
    const appendDataForConfirm = function (data) {
        data.append('month', month);
    }
    const appendDataForSave = function (data) {
        data.append('month', month);
    }
    setupFileUpload([{
        fileName: 'upload_file',
        appendDataForConfirm: appendDataForConfirm,
        appendDataForSave: appendDataForSave
    }]);
    $(document).on('click', '[data-upload-modal]', function () {
        _modal.create(this, 'upload-modal', [], false);
    });

    // エリア（複数可）絞り込み決まるとき
    $(document).on('click', '#running_order-submit_area', function () {
       // console.log('submit_area: ');
        load_contents(null, false);
    });

}
