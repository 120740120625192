/**
 * 検索フォームの変更を検知し検索を行う
 */
$(function () {
    // フォーム外へのチェンジイベントのバブリングを禁止
    $(document).on('change', 'form', function(e) {
        e.stopPropagation();
    });
    // 検索フォームの変更時
    $(document).on('change', '#search-form, #toolbar-keyword-search', function(e) {
        let isShowToastErrors = false
        if(is_route('order_recieve.index'))
        {
            const searchParams = new URLSearchParams(window.location.search)
            if(searchParams.has('start_ordered_at') || searchParams.has('end_ordered_at') || searchParams.has('start_complete_at') || searchParams.has('start_complete_at'))
            {
                isShowToastErrors = true // 受注画面の日付範囲検索のエラーメッセージ対応
            }
        }
        else if(is_route('running_order.index')||is_route('yearly_running_order.index'))
        {
            if((e.target.id && e.target.id === 'search-form-area')|| e.target.closest('.group-select'))
            {
                return; // 年額,月額注文管理のエリア（複数）選択なら自動ロードしない
            }
        }
        // コンテンツを読込
        load_contents(null, false,'#search-form', '#contents',isShowToastErrors);
    });
    // キーワード検索の実行ボタンクリック時
    $(document).on('click', '#toolbar-keyword-search-submit, #toolbar-keyword-search-reset', function() {
        // コンテンツを読込
        load_contents(null, false);
    });

    // 表示設定の表示時
    $(document).on('click', '[data-display-setting-modal]', function(e) {
        // 設定モーダルを表示
        _modal.create(this, 'display-setting-modal', ['route', 'path'], true);
    });
    // 表示設定の適用時
    $(document).on('click', '[data-display-save-modal]', function(e) {
        _modal.submit(this, 'display-save-modal');
    });
    // 表示設定の初期化時
    $(document).on('click', '[data-display-delete-modal]', function(e) {
        _modal.submit(this, 'display-delete-modal');
    });
    // 表示設定の適用時
    $(document).on('click', '.sort-column', function(e) {
        let datas = [];
        datas.push({'name' : 'route', 'value' : $(this).data('route')});
        datas.push({'name' : 'order', 'value' : $(this).data('order')});
        datas.push({'name' : 'sort', 'value' : $(this).data('sort')});
        _ajax.post($(this).data('action'), datas, function(results) {
            load_contents();
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });
});
