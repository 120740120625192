if (is_route('shipping.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() { //TODO bss?
        shipping_event_set();
        shipping_setteing_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.shipping_event_set = function() {
    // ボタンクリック時のバブリングを禁止
    $(document).on('click', '.shipping_license_file_link', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });

    /*---- カレンダー１日分出荷情報表示時 ----*/
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['date', 'shipping_status'], true);
    });

    /*---- 出荷詳細表示時 ----*/
    /*---- 梱包画面表示時 ----*/
    /*---- 出荷画面表示時 ----*/
    $(document).on('click', '[data-setting-modal]', function (e) {
        e.stopPropagation();
        _modal.create(this, 'setting-modal', ['id'], true);
    });

    /*---- 品目集計表示時 ---*/
    $(document).on('click', '[data-component-modal]', function () {
        _modal.create(this, 'component-modal', ['date'], true);
    });

    /*---- 設定時 ----*/

    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {
            $('[data-detail-modal=shipping-detail-modal][data-date=' + moment(results.rollcall.rollcalled_at).format("YYYY-MM-DD") + ']').trigger('click');
        });
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    $(document).on('click', '[data-delete-modal]', function (e) {
        _modal.submit(this, 'delete-modal', function(results) {
            $(".calendar-date-container[data-date='" + $(e.target).attr('data-shipping-date') + "']").trigger('click');
        });
    });

    //品目名検索欄でエンター押下の場合
    $(document).on('click', '#search_item_button', function (e) {
        load_contents(null, true, '#search_item');
    });

    //シリアル番号検索欄でエンター押下の場合
    $(document).on('click', '#search_serial_button', function (e) {
        load_contents(null, true, '#search_serial');
    });

    //input変更時に#search-formへイベント伝播抑止（load_contents多重コール防止）
    $('#search_item').on('change', function(e) {
        e.stopPropagation();
    });
    $('#search_serial').on('change', function(e) {
        e.stopPropagation();
    });

    // 出荷処理クリック時
    $(document).on('click', '#shipping-multi-delete-button', function () {
        $(".shipping-delete").prop('checked', $(this).prop('checked'));
    });

    $(document).on('click', '.transfered .btn-group', function (event) {
        event.preventDefault();
    });
}

/**
 * 設定モーダル表示時のイベント設定
 * ダッシュボードからモーダル表示する際にも呼び出される
 */
window.shipping_setteing_event_set = function() {

    /*---- 印刷ボタン押下時 ----*/

    // 印刷時
    $(document).on('click', '#search-form-action_print', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('.shipping-list').serializeArray();
        datas.push({'name': 'date', 'value': $(this).data('date')});
        datas.push({'name': 'order_shipping_id', 'value': $(this).data('id')});

        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 発送指示書印刷ボタン押下時 ----*/

    // 印刷時
    $(document).on('click', '#shipping-action_print', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#shipping-instructions').serializeArray();
        // datas.push({'name': 'order_shipping_id', 'value': $(this).data('id')});

        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- CSVダウンロード時 ----*/

    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        datas.push({'name': 'date', 'value': $(this).data('date')});
        // ポスト送信を実行
        post(action, datas);
    });

    // OSO納期回答CSVダウンロード時
    $(document).on('click', '#search-form-action_oso_answer_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        datas.push({'name': 'date', 'value': $(this).data('date')});
        // ポスト送信を実行
        post(action, datas);
    });

    /*---- Excel出力ボタン押下時 ----*/

    // Excel出力時
    $(document).on('click', '#search-form-action_excel_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('.shipping-list').serializeArray();
        datas.push({'name': 'date', 'value': $(this).data('date')});
        datas.push({'name': 'order_shipping_id', 'value': $(this).data('id')});

        // BlobでExcel出力を別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 発送伝票番号、シリアル番号の個数表示用 ----*/
    // 発送伝票番号変更時
    $(document).on('input', '.slip_code', function (e) {
        // カウンター更新
        let count = this.value ? this.value.split(',').length : 0;
        $('#slip_code_counter').empty();
        $('#slip_code_counter').append(count + "個");
    });
    $(document).on('keydown', '.slip_code', function (e) {
        if (e.keyCode === 188) { // カンマ入力時
            convertSlipcode(this);
        }
    });
    $(document).on('focusout', '.slip_code', function (e) {
        convertSlipcode(this);
    });

    // シリアル番号変更時
    $(document).on('input', '.serial_code', function (e) {
        // カウンター更新
        let count = this.value ? this.value.split(',').length : 0;
        $codeCounter = $(this).closest('.row').find('.code_counter');
        $codeCounter.empty();
        $codeCounter.append(count + "個");
    });
    $(document).on('keydown', '.serial_code', function (e) {

        if (e.keyCode === 188) { // カンマ入力時
            convertSerialcode(this);
        }
    });
    $(document).on('focusout', '.serial_code', function (e) {
        convertSerialcode(this);
    });

    const convertSlipcode = function(element){
        element.value = element.value.replace(/[a-zA-Z]/g, '');
    }

    const convertSerialcode = function(element){
        let values = element.value.split(',');

        if(element.dataset.product_id == 3){ // ICカードリーダの場合
            values.forEach(function (value, index) {
                if(35 <= value.length){
                    values[index] = value.slice(19, 35);
                }
            });
            element.value = values.join(',');
        }
    }
}
