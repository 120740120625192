/**
 * ファイルのイベントを設定する
 */
 $(function () {
    
    // 画像選択時の処理
    $(document).on('change', '.upload_file', function(e) {
        let accept = $(this).attr('accept');
        let name = $(this).data('name');

        let fn = 'input[name=_' + name + ']';
        if ($(fn).length > 0) {
            let path = $(this).val().replace(/\\/g, '/');
            let basename = path.split('/').reverse()[0];

            $(fn).val(basename);
            $(".filename-" + name).html(basename);
        }

        if (!accept.startsWith('text')) {
            let preview = create_id($(this).data('preview'));
            var reader = new FileReader();
            reader.onload = function (e) {
                if ($(preview).length > 0) {
                    $(preview).attr('src', e.target.result);
                }
                $('input[name=' + name + ']').val(e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    });
});