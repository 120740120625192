/**
 * ログインページ用のスクリプト
 * @uses 管理画面と設計が異なるため共通部分は呼び出すもののbss独自イベントなどは使用しない。
 */
if (is_route('user.login')) {

    $(function () {

        // ログインボタンクリック時
        $(document).on('click', '#login-form-submit', function () {
            let formId = $(this).data('login-form');
            let action = $(formId).data('action');
            // 送信
            _ajax.submit(formId, function (results) {
                if (results.status) {
                    location.href = action;
                } else {
                    _modal.show('#login-force-modal');
                }
            }, function(xhr, status, errorThrown){
                // エラーをセットする
                _error.set(formId, xhr, status, errorThrown, false);
            });
            return false;
        });

        // 強制ログインボタンクリック時
        $(document).on('click', '#login-form-force-submit', function () {
            let formId = $(this).data('login-form');
            let action = $(formId).data('action');
            $(formId).append($('<input></input>', {
                'type' : 'hidden', 
                'name' : 'force_login', 
                'value' : 1
            }));
            _modal.hide('#login-force-modal');
            // 送信
            _ajax.submit(formId, function (results) {
                location.href = action;
            }, function(xhr, status, errorThrown){
                // エラーをセットする
                _error.set(formId, xhr, status, errorThrown, false);
            });
            return false;
        });

    });
}