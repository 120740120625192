/**
 * Ajax系セレクトのオプション設定を行う
 */
 $(function () {

    // セレクトの値をセットする
    function setSelect(elm, find, datas = {}, callback = null) {
        let select = $(elm).parents('form').find(find);
        if (select.length > 0) {
            let action = $(select).data('action');
            let value = $(select).val();
            if (find == 'select.account-user-select') {
                datas.selected = value;
            }
            _ajax.post(action, datas, function (results) {
                $(select).empty();
                $(select).select2({
                    width: "100%",
                    data: results.options,
                    val: value
                });
                $(select).val(value).trigger('change');
                if (callback != null) {
                    callback(elm);
                }
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }
    }

    // 選択初期値をセットする
    function setSelected(elm, find, datas = {}) {
        let select = $(elm).parents('form').find(find);
        if (select.length > 0) {
            let action = $(select).data('selected-action');
            if (action != null) {
                _ajax.post(action, datas, function (results) {
                    $(select).val(results.value);
                }, function(xhr, status, errorThrown){
                    _error.omitted(xhr, status, errorThrown);
                });
            } else {
                $(select).val('');
            }
        }
    }

    // セレクトの値をセットする
    function setMultiSelect(elm, find, datas = {}, callback = null) {
        let select = $(elm).parents('form').find(find);
        if (select.length > 0) {
            let action = $(select).data('action');
            _ajax.post(action, datas, function (results) {
                $(select).empty();
                $.each(results.options, function(index, option) {
                    $(select).append($('<option></option>', {
                        'value': option.id
                    }).text(option.text));
                })
                $(select).multipleSelect('refreshOptions', {
                    filter: false
                });
                $(select).multipleSelect('setSelects', []);
                if (callback != null) {
                    callback(elm);
                }
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }
    }

    // セレクトの選択値をセットする
    function setMultiSelected(elm, find, datas = {}) {
        let select = $(elm).parents('form').find(find);
        if (select.length > 0) {
            let action = $(select).data('selected-action');
            _ajax.post(action, datas, function (results) {
                if (results.value != null) {
                    $(select).multipleSelect('setSelects', results.value);
                } else {
                    $(select).multipleSelect('setSelects', []);
                }
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }
    }

    // チェックボックスの値をセットする
    function setOccupationCheckbox(elm, datas = {}) {
        let checkbox = $(elm).parents('form').find('.occupation-check');
        if (checkbox.length > 0) {
            let action = $(checkbox).data('action');
            _ajax.post(action, datas, function (results) {
                let index = results.options.findIndex((v) => v.id == '');
                if (index == 0) {
                    results.options.splice(index, 1);
                }
                $(checkbox).children('div:not(.copy,.occupation-check-not-found)').remove();
                if (results.options.length > 0) {
                    $.each(results.options, function(index, option) {
                        let clone = $(checkbox).find('.copy').clone();
                        let input = $(clone).find('input');
                        let label = $(clone).find('label');
                        let id = $(input).attr('id') + option.id;
                        $(input).attr('id', id);
                        $(input).val(option.id);
                        $(input).data('occupation-type', option.type);
                        $(label).attr('for', id);
                        $(label).text(option.text);
                        $(clone).removeClass('copy').removeClass('display-none');
                        $(checkbox).append($(clone));
                    });
                    $(checkbox).find('.occupation-check-not-found').addClass('display-none');
                } else {
                    $(checkbox).find('.occupation-check-not-found').removeClass('display-none');
                }
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }
     }

     // セレクトの値をセットする
    function setSelectCard(elm, find, datas = {}, callback = null) {
        let select = $(elm).parents('.card, .card-change-end-user-name').find(find);
        if (select.length > 0) {
            let action = $(select).data('action');
            let value = $(select).val();
            _ajax.post(action, datas, function (results) {
                $(select).empty();
                $(results.options).each(function () {
                    $(select).append('<option value="' + $(this)[0].id + '">' + $(this)[0].text + '</option>');
                });
                $(select).val(value);
                $(select).val(value).trigger('change');
                if (callback != null) {
                    callback(elm);
                }
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }
    }

    // ユーザーとドライバーのセレクト設定時の共通処置
    function commonUserSelectedEvent(elm) {
        setSelect(elm, 'select.rollcall-authorizer-select', {'user_id': $(elm).val(), 'type': 'authorizer'});
        setSelect(elm, 'select.vehicle-select', {'user_id': $(elm).val()});
    }

    /*---- グループ会社のセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.holding-select', function () {
        setSelect(this, 'select.company-select', {'holding_id': $(this).val()});
        setMultiSelect(this, 'select.group-select', {'holding_id': $(this).val()});
        setSelect(this, 'select.section-select');
        setSelect(this, 'select.notificationsection-select');
        setSelect(this, 'select.employment-select');
        setSelect(this, 'select.occupation-select');
        setSelect(this, 'select.post-select');
        setOccupationCheckbox(this);
    });

    /*---- 会社のセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.company-select', function () {
        setSelect(this, 'select.section-select', {'company_id': $(this).val()});
        setSelect(this, 'select.notificationsection-select', {'company_id': $(this).val()});
        setSelect(this, 'select.employment-select');
        setSelect(this, 'select.occupation-select');
        setSelect(this, 'select.post-select');
        setOccupationCheckbox(this);
    });

    /*---- 営業所のセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.section-select', function () {
        console.log("営業所のセレクト設定時");//TODO delete
        console.log("$(this).val():");//TODO delete
        console.log($(this).val());//TODO delete


        setSelect(this, 'select.employment-select', {'section_id': $(this).val()});
        setSelect(this, 'select.occupation-select', {'section_id': $(this).val()});
        setSelect(this, 'select.post-select', {'section_id': $(this).val()});
        setSelect(this, 'select.account-user-select', {'section_id': $(this).val(), 'type': 'account'});
        setSelect(this, 'select.user-select', {'section_id': $(this).val()});
        setSelect(this, 'select.driver-select', {'section_id': $(this).val(), 'type': 'driver'});
        setSelect(this, 'select.rollcall-assistant-select', {'section_id': $(this).val(), 'type': 'assistant'});
        setSelect(this, 'select.rollcall-enforcer-select', {'section_id': $(this).val(), 'type': 'enforcer'});
        setSelect(this, 'select.rollcall-authorizer-select', {'section_id': $(this).val(), 'type': 'authorizer'});
        setOccupationCheckbox(this, {'section_id': $(this).val()});
    });

    /*---- ユーザーのセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.user-select', function () {
        commonUserSelectedEvent(this);
        setMultiSelected(this, 'select.group-select', {'user_id': $(this).val()})
    });

    /*---- ドライバーのセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.driver-select', function () {
        commonUserSelectedEvent(this);
    });

    /*---- アカウント未設定ユーザーのセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.account-user-select', function () {
        setMultiSelected(this, 'select.group-select', {'user_id': $(this).val()})
    });


    // セレクト２設定時
    $(document).on('select2:selecting', '.select2', function (e) {
        $(this).val(e.params.args.data.id);
        $(this).trigger('change');
    });

    /*---- メーカー発注のセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.makerorder-section-select', function () {
        let elm = this;
        let find = 'select.makerorder-section-select';
        let datas = {'maker_name': $(this).val()};
        let select = $(elm).parents('form').find(find);
        let action = $(select).data('action');
        let value = $(select).val();

        _ajax.post(action, datas, function (results) {
            let elemFragment = document.getElementById("template").content.firstElementChild;
            let selectElem = $(elemFragment).find('select');
            let selectChildren = selectElem.children();
            
            //templateのselectの既存optionを削除
            for(let key in selectChildren) 
                if(selectChildren[key].tagName === 'OPTION')
                    selectChildren[key].remove();
            //templateのselectに新option追加
            for(let key in results) {
                selectElem.append('<option value="' + results[key].name + '">'  + results[key].name + '</option>');
            }

        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

     /*---- エンドグループ会社のセレクト設定時 ----*/

    // セレクト設定時
     $(document).on('change', 'select.end_holding-select', function () {
         if (this.id === 'order-setting-modal-end_holding_id') {
             // 注文編集モーダルの基本画面の配送先GP会社名選択変更の場合
             $('select.end_holding-select').each(function () {
                 if (this.id !== 'order-setting-modal-end_holding_id') {
                     // 別の配送先GP会社名選択も変更
                     $(this).val($('#order-setting-modal-end_holding_id').val()).trigger('change')
                 }
             })
         } else {
             if (!$(this).find('[data-select2-tag="true"]').length || $(this).val() >= 0) {
                 const endHoldingID = $(this).val()
                 setSelectCard(this, 'select.end_company-select', {'end_holding_id': endHoldingID}, function (elm) {
                     $($(elm).parents('.card').find('select.end_company-select')).attr('end_holding_id', endHoldingID)
                 });
                 setSelectCard(this, 'select.end_section-select');
             }
         }
     });

     /*---- エンド会社のセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.end_company-select', function () {
        if ($(this).data('skip-end-company-change-handler')) {
            return;
        }
        if(!$(this).find('[data-select2-tag="true"]').length || $(this).val()>=0) {
            const endCompanyID = $(this).val();
            setSelectCard(this, 'select.end_section-select', {'end_company_id': $(this).val()}
                ,function (elm) {
                $($(elm).parents('.card').find('select.end_section-select')).attr('end_company_id', endCompanyID)
                }
            );
        }
    });

});
