import { setupFileUpload } from "../util/upload";

/**
 * 回収管理ページ用のスクリプト
 */
if (is_route('replacement_collect.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        replacement_collect_event_set();
        replacement_collect_setting_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.replacement_collect_event_set = function() {

    // 設定表示
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['holding_code', 'company_code', 'shipping_section_code', 'to_shipped_at'], false);
    });

    // 回収シリアルExcelアップロード
    setupFileUpload([{ fileName: 'upload_file'}]);
    $(document).on('click', '[data-upload-serial-modal]', function () {
        _modal.create(this, 'upload-serial-modal', [], false);
    });

    // 未回収シリアルダウンロード
    $(document).on('click', '#search-form-action_download_serial', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });

}

/**
 * 設定モーダル表示時のイベント設定
 * @uses ダッシュボードからモーダル表示する際にも呼び出される
 */
window.replacement_collect_setting_event_set = function () {

    // 一括のチェックボックスクリック
    $(document).on('change', '.is_checked_all', function () {
        $(".is_checked").prop('checked', $(this).prop('checked')).change();
    });

    // 設定画面の保存
    $(document).on('click', '[data-save-modal]', function () {
        // エラーリセット
        _error.reset()

        let datas = $('#replacement_collect-setting-modal-form').serialize();
        let modalId = create_id($(this).data('save-modal'));
        let action = $(this).data('action');
        // 送信
        _ajax.post(action, datas, function (results) {
            // html, jsonの可能性があるため判定
            let isJson = true;
            try {
                var json = JSON.parse(results);
            } catch (error) {
                isJson = false;
            }

            if (isJson) {
                if(json.messages){
                    // メッセージを表示
                    _complete.set(json);
                }
                if(json.warnings){
                    // 注意メッセージを表示
                    _complete.warningSet(json);
                }
                load_contents(function () {
                    $('[data-setting-modal=replacement_collect-setting-modal][data-holding_code=' + json.holding_code + '][data-company_code=' + json.company_code + '][data-shipping_section_code=' + json.shipping_section_code + '][data-to_shipped_at=' + json.to_shipped_at + ']').trigger('click');
                });
                return;
            } else{
                // モーダルのコンテンツを空にする
                $(modalId + ' .modal-content').empty();
                // モーダルにコンテンツを追加する
                $(modalId + ' .modal-content').html(results);
                initialize();
                _modal.show(modalId);
            }
        }, function (xhr, status, errorThrown) {
           // エラーをセットする
            _error.set('#replacement_collect-setting-modal', xhr, status, errorThrown, true, false);
        }, 'html');
    });
}