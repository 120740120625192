/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// configre

require('./bootstrap');
require('./extension');

// outside modules

window.moment = require('moment');
require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.ja.min.js')
window.toastr = require('toastr');
require('select2');
require('popper.js/dist/popper.min.js');
require('tempusdominus-bootstrap-4');
require('multiple-select/dist/multiple-select.min');
require('bs-stepper/dist/js/bs-stepper.min.js');
window.YubinBango = require('yubinbango-core2');
require('jquery-ui/ui/widgets/sortable.js');

// original modules

require('./modules/funcitons');

window._complete = require('./modules/complete');
window._error = require('./modules/error');
window._ajax = require('./modules/ajax');
window._overlay = require('./modules/overlay');
window._modal = require('./modules/modal');
window._request = require('./modules/request');
window._format = require('./modules/format');

// common script files

require('./common/firebase');
require('./common/affiliation');
require('./common/message');
require('./common/overlay');
require('./common/search');
require('./common/select');
require('./common/inputs');
require('./common/dnd');
require('./common/step');
require('./common/initialize');
require('./common/button');

// pages script files

require('./pages/login.index');
require('./pages/setup.index');
require('./pages/dashboard.index');
require('./pages/holding.index');
require('./pages/company.index');
require('./pages/section.index');
require('./pages/end_holding.index');
require('./pages/end_company.index');
require('./pages/end_section.index');
require('./pages/account.index');
require('./pages/group.index');
require('./pages/user.index');
require('./pages/shipping.index');
require('./pages/information.index');
require('./pages/order.index');
require('./pages/makerorder.index');
require('./pages/product.index');
require('./pages/bill.index');
require('./pages/contract.index');
require('./pages/stock.index');
require('./pages/replacement_schedule.index');
require('./pages/defect_management.index');
require('./pages/replacement_shipping.index');
require('./pages/replacement_collect.index');
require('./pages/running_order.index');
require('./pages/yearly_running_order.index');
require('./pages/cotos.index');

// pages BSS script files
require('./pages/Bss/order.index');
require('./pages/Bss/contract.index');
require('./pages/Bss/bill.index');
require('./pages/Bss/shipping.index');
require('./pages/Bss/running_order.index');
require('./pages/Bss/yearly_running_order.index');
require('./pages/Bss/dashboard.index');
