/**
 * 社員台帳ページ用のスクリプト
 */
if (is_route('makerorder.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() { //TODO bss?
        makerorder_event_set();
        makerorder_setteing_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.makerorder_event_set = function() {
    // ボタンクリック時のバブリングを禁止
    $(document).on('click', '.makerorder_license_file_link', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['id', 'itemid']);
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id', 'type']);
    });
    
    /*---- 設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {
            $('[data-detail-modal=makerorder-detail-modal][data-id=' + results.id + ']').trigger('click');
        }, function() {});
    });

    /*---- 一括削除チェックボックス操作時 ----*/

    // 一括のチェックボックスクリック時
    $(document).on('click', '#makerorder-all-delete', function () {
        $(".makerorder-delete").prop('checked', $(this).prop('checked'));
    });
    // 一覧のチェックボックスクリック時
    $(document).on('click', '.makerorder-delete', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });
    // 一括・一覧のチェックボックス変更時
    $(document).on('change', '#makerorder-all-delete, .makerorder-delete', function () {
        // 一覧のチェック状態を判定し削除ボタンを有効化
        $('#makerorder-multi-delete-button').prop('disabled', ($('.makerorder-delete:checked').length == 0));
    });

    /*---- 削除確認時 ----*/

    // 一括削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        var ids = ($(this).data('id') == null ? [] : [$(this).data('id')]);
        if ($(this).data('id') == null) {
            $('.makerorder-delete[name="ids[]"]:checked').map(function() {
                ids.push($(this).val());
            });
        }
        if (ids.length > 0) {
            _modal.create_with_data(this, 'confirm-modal', {'ids': ids});
        }
    });

    /*---- 削除処理 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            _modal.hide('#makerorder-detail-modal');
        });
    });

    /*---- テーブル行追加 ----*/
    $(document).on('click', '[data-tablerowadd-modal]', function () {
        //TODO 途中 クライアント側でhtml情報もってOK？
        
    });

    $(document).on('click', '.transfered .btn-group', function (event) {
        event.preventDefault();
    });

    
    /*---- CSV関連はuser.index.jsより流用 ---- */
    /*---- CSVダウンロード時 ----*/

    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {

        console.log("@makerorder.js #search-form-action_download");//TODO push delete

        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        datas.push({'name': 'date', 'value': $(this).data('date')});
        // ポスト送信を実行
        post(action, datas);
    });

    
    /*---- CSVアップロード表示時 ----*/

    // CSVアップロード表示時
    $(document).on('click', '#search-form-action_upload', function () {
        console.log("@makerorder.js #search-form-action_upload");//TODO push delete

        // 初期化処理
        $('#user-csv-setting-modal-csv-submit-message').addClass('display-none');
        $('#user-csv-setting-modal-csv-submit-message').empty();
        $('#user-csv-setting-modal-csv-error').addClass('display-none');
        $('#user-csv-setting-modal-csv-error').empty();
        $('#user-csv-setting-modal-csv').val('');
    });

    /*---- CSVアップロード確認時 ----*/

    // CSVアップロード確認時
    $(document).on('change', '#user-csv-setting-modal-csv', function () {
        console.log("@makerorder.js #user-csv-setting-modal-csv");//TODO push delete

        let modalId = '#user-csv-setting-modal';
        let formId = '#user-csv-setting-modal-form';
        let action = $(formId).data('confirm-action');
        let datas = new FormData($(formId).get(0));
        $(modalId + '-csv-error').addClass('display-none');
        $(modalId + '-csv-error').empty();
        $(modalId + '-csv-submit-message').addClass('display-none');
        $(modalId + '-csv-submit-message').empty();
        $("[data-csv-save-modal]").prop("disabled", true);
        // 送信
        _ajax.file(action, datas, function (results) {
            $(modalId + '-csv-submit-message').removeClass('display-none');
            $(modalId + '-csv-submit-message').text(results.messages[0]);
            $("[data-csv-save-modal]").prop("disabled", false);
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
            $(this).val('');
            // フォームにエラーメッセージを配置
            $.each(xhr.responseJSON.errors, function(name, message) {
                $(modalId + '-csv-error').removeClass('display-none');
                $(modalId + '-csv-error').append($('<li></li>').text(message));
            });
        });
    });

    /*---- CSVアップロード時 ----*/

    // 設定表示時
    $(document).on('click', '[data-csv-save-modal]', function () {
        console.log("@makerorder.js data-csv-save-modal");//TODO push delete
        
        let modalId = '#user-csv-setting-modal';
        let formId = '#user-csv-setting-modal-form';
        let action = $(formId).attr('action');
        let datas = new FormData($(formId).get(0));
        // 送信前にエラーをリセット
        _error.reset()
        // 送信
        _ajax.file(action, datas, function (results) {
            // 完了メッセージをセットする
            _complete.set(results);
            // モーダルを閉じる
            _modal.hide(modalId);
            // コンテンツの読込
            load_contents();
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.omitted(xhr, status, errorThrown);
        });
    });

}
/**
 * 設定モーダル表示時のイベント設定
 * ダッシュボードからモーダル表示する際にも呼び出される
 */
window.makerorder_setteing_event_set = function() {
    /*---- 商品の追加ボタンを押下(メーカー発注編集・新規追加モーダルで入力項目を追加する) ----*/

    $(document).on('click', 'button[name="add_product"]', function () {
        let table = $(this).parents('.table-holder').find('.order-table');
        let tableid = table.attr('id');
        let tr_count = $('#' + tableid + ' tr').length;
        tr_count--;
        
        let template = $(document).find('#template')[0].content;
        let tr = document.importNode(template, true);
        let jq_tr = $(tr);

        let new_product_name_id = "makerorder-setting-modal-product_name_" + tr_count;
        jq_tr.find('#template_product_name').attr('id', new_product_name_id);
        jq_tr.find('#template_product_name-errors').attr('id', new_product_name_id + '-errors');
        jq_tr.find('#template_order_item_id').val('new' + tr_count);

        jq_tr.find('#template_delete_check').val('new' + tr_count);

        let new_item_count_id = "makerorder-setting-modal-item_count_" + tr_count;
        jq_tr.find('#template_item_count').attr('id', new_item_count_id);
        jq_tr.find('#template_item_count-errors').attr('id', new_item_count_id + '-errors');

        let tbody = table.find('tbody');
        tbody.append(tr);

        window.initialize_datepicker();//date-picker再初期化
    });

    /* --- tr要素を引数として、tr配下にエラーメッセージ表示用IDを振る --- */
    let set_error_disp_ids_to_transaction_tr = function(tr_elem, index) {

        let jq_tr = $(tr_elem);

        //エラーメッセージ表示箇所にID設定
        let new_id_inout_date = "makerorder-transaction-modal-inout_date_" + index;
        let new_id_inout_date_errors = new_id_inout_date + "-errors";
        let new_id_inout_count = "makerorder-transaction-modal-inout_count_" + index;
        let new_id_inout_count_errors = new_id_inout_count + "-errors";

        jq_tr.find('input[id^="makerorder-transaction-modal-inout_date_"').attr('id', new_id_inout_date);
        jq_tr.find('div[id^="makerorder-transaction-modal-inout_date_"').attr('id', new_id_inout_date_errors);
        jq_tr.find('input[id^="makerorder-transaction-modal-inout_count_"').attr('id', new_id_inout_count);
        jq_tr.find('div[id^="makerorder-transaction-modal-inout_count_"').attr('id', new_id_inout_count_errors);

    }

    //新規入力行のIDを振り直し(上の行が大きいindexになるように振り直し)
    let refresh_input_row_id = function() {
        let table = $(document).find('.transaction-table');

        let tmp_input_tr_index = 0;

        table.find('.input_row').each(function(index, element) {
            
            set_error_disp_ids_to_transaction_tr(element, tmp_input_tr_index);

            tmp_input_tr_index++;

        });
    }

    /* --- メーカー発注詳細画面で実績追加ボタン押下時 */
    $(document).on('click', 'button[name="add_transaction"]', function () {
        
        let table = $(document).find('.transaction-table');
        let tableid = table.attr('id');
        let input_tr_count = $('#' + tableid).find('.input_row').length;
        
        let template = $(document).find('#transaction_row_template')[0].content;

        let tr = document.importNode(template, true);

        let tbody = table.find('tbody');
        tbody.prepend(tr);

        refresh_input_row_id();

        window.initialize_datepicker();//date-picker再初期化
    });

    //入荷・メーカー納期回答登録 削除ボタン押下時
    $(document).on('click', 'button[name="transaction_delete"]', function (event) {
        //ボタンを含む行を削除
        $(event.target).closest('.input_row').remove();

        refresh_input_row_id();

    });

    //入荷・メーカー納期回答登録 保存ボタン押下時
    $(document).on('click', 'button[name="transaction_save"]', function (event) {

        _modal.submit(this, 'transaction-modal', function(results) {

            //表示更新
            update_tr = $('[data-itemid=' + results.itemid + ']');
            update_tr.find('.answered_deadline_count').html(results.answered_deadline_count);
            update_tr.find('.receipt_count').html(results.receipt_count);
            update_tr.find('.order_backlog').html(parseInt(results.item_count) - parseInt(results.receipt_count));
            update_tr.find('.receipt_date').html(results.receipt_date);
            
            $('[data-itemid=' + results.itemid + ']').trigger('click');

        }, function() {});

    });

    /*---- ステップボタンクリック時（新規登録時） ----*/

    // 前へ
    $(document).on('click', '[data-prev-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let prevPart = $(this).data('prev-step');
        let prevPartTrigger = create_id(prevPart + '-trigger');
        let prevPartSubmit = create_id(prevPart + '-submit');
        $(currentPartSubmit).addClass('display-none');
        $(prevPartSubmit).removeClass('display-none');
        bs_step_trigger(prevPartTrigger);
    });

    // 次へ
    $(document).on('click', '[data-next-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let nextPart = $(this).data('next-step');
        let nextPartTrigger = create_id(nextPart + '-trigger');
        let nextPartSubmit = create_id(nextPart + '-submit');
        // ModalとPartセレクターの指定
        let modalId = '#makerorder-setting-modal';
        let formId = '#makerorder-setting-modal-form';
        let action = $(formId).data('confirm-action');
        // 確認対象のパートをパラメータにセット
        $('#makerorder-setting-modal-confirm_step').val($(this).data('confirm-step'));
        // 送信前にエラーをリセット
        _error.reset()
        makerorder_set_steper_error(false);
        // 確認用のpost送信
        _ajax.confirm_submit(formId, action, function (results) {
            $(currentPartSubmit).addClass('display-none');
            $(nextPartSubmit).removeClass('display-none');
            bs_step_trigger(nextPartTrigger);
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.set(modalId, xhr, status, errorThrown);
            // ステップエラーを表示
            makerorder_set_steper_error();
        });
    });

    /*---- 追加ボタンを押下(入荷モーダルで入力行を追加する) ----*/
    $(document).on('click', '#instock-part button[name="add_instock"]', function(){
        
        let tr_count = $('#instock_table tr').length;
        tr_count--;
        
        let table = $('#instock_table');
        let template = $(document).find('#template')[0].content;
        let tr = document.importNode(template, true);

        let jq_tr = $(tr);
        jq_tr.find()

        //エラーメッセージ表示箇所にID設定
        let new_id_in_stock_item_count = "makerorder-setting-modal-in_stock_item_count_" + tr_count;
        let new_id_in_stock_item_count_errors = new_id_in_stock_item_count + "-errors";
        let new_id_in_stock_date = "makerorder-setting-modal-in_stock_date_" + tr_count;
        let new_id_in_stock_date_errors = new_id_in_stock_date + "-errors";

        jq_tr.find('input[id^="input-in_stock_item_count"').attr('id', new_id_in_stock_item_count);
        jq_tr.find('div[id^="input-in_stock_item_count"').attr('id', new_id_in_stock_item_count_errors);
        jq_tr.find('input[id^="input-in_stock_date"').attr('id', new_id_in_stock_date);
        jq_tr.find('div[id^="input-in_stock_date"').attr('id', new_id_in_stock_date_errors);

        let tbody = table.find('tbody');
        tbody.append(tr);
        window.initialize_datepicker();//date-picker再初期化
    });

    /*---- 入荷モーダルで入力行削除ボタン押下時 ----*/
    $(document).on('click', '#instock-part button.btn-danger', function(event){
        event.target.parentNode.parentNode.remove();
        window.initialize_datepicker();//date-picker再初期化
    });

    /*---- 資格情報の追加ボタンを押下(資格入力項目を追加する) ----*/
    $(document).on('click', '#makerorder_setting_item_part button[name="add_card"]', function(){

        var count = $('#makerorder_setting_item_part .card').length;
        var card = $('#makerorder_setting_item_part .card:first').clone();

        // 削除項目あれば消す
        card.find('input[name="instock_delete[]"]').remove();
        card.find('#label_instock_delete').remove();

        // 追加要素の初期化
        card.find('input').each(function() {
            $(this).val('');
            var id = $(this).attr('id');
            var newId = id.replace(/1_/g , count + '_');
            var label = card.find('label[for="' + id + '"]');
            var error = card.find('#' + id + '-errors');
            // ID書き換え
            $(this).attr('id', newId);
            label.attr('for', newId);

            // error初期化
            if (error.length > 0) {
                error.attr('id', newId + '-errors');
                error.find('span').html('');
            }
        });

        // 最後に追加
        card.insertAfter('#makerorder_setting_item_part .card:last');

        initialize();

    });

    
}

/**
 * ステッパーへのエラー表示処理
 * @param {*} showError
 */
window.makerorder_set_steper_error = function (showError = true) {
    if (showError) {
        $('#base-part-trigger').toggleClass('error', ($('#base-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#items-part-trigger').toggleClass('error', ($('#items-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#confirm-part-trigger').toggleClass('error', ($('#confirm-part').find('.invalid-feedback:not(.copy)').length > 0));
    } else {
        $('#base-part-trigger').removeClass('error');
        $('#items-part-trigger').removeClass('error');
        $('#confirm-part-trigger').removeClass('error');
    }
}
