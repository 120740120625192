/**
 * 営業所設定ページ用のスクリプト
 */
 if (is_route('section.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        section_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.section_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id']);
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {
            let keys = Object.keys(results);
            if (keys.find(key => key == 'company')) {
                $('[data-replace-name-company-id=' + results.company.id + ']').text(results.company.name);   
            }
            if (keys.find(key => key == 'section')) {
                $('[data-replace-name-section-id=' + results.section.id + ']').text(results.section.name);   
            }
        });
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });
}