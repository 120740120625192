/**
 * バリデーション時のエラーメッセージの表示を行う
 * @param {*} selector
 * @param {*} error
 */
exports.set = function (selector, xhr, status, errorThrown, showToast = true, isShowToastErrors=false) {
    try {
        // Jsonのパースが行われていない場合はTextを元にパースする
        if (xhr.responseJSON == null) {
            xhr.responseJSON = JSON.parse(xhr.responseText);
        }
        // BadRequest時
        if (xhr.status == 400) {
            let json = xhr.responseJSON;
            let subject = json.subject;
            let errors = json.errors;
            // フォームにエラーメッセージを配置
            $.each(errors, function(name, message) {
                if ($(selector + '-' + name.replace(/\./g , '_') + '-errors').length > 0) {
                    // 配列項目全てにエラーを表示する
                    if ($(selector + '-' + name.replace(/\./g , '_')).hasClass('multi-error')) {
                        module.exports.message(selector + '-' + name.replace(/\./g, '_') + '-errors', message);
                    }
                    module.exports.message(selector + '-' + name + '-errors', message);
                } else {
                    if ($(selector + '-' + name.split('.')[0] + '-errors').length > 0) {
                        module.exports.message(selector + '-' + name.split('.')[0] + '-errors', message);
                    }
                }
            });
            if (showToast) {
                if(isShowToastErrors&&errors&&Object.keys(errors).length>0)
                {
                    for (const [key, value] of Object.entries(errors)) {
                        toastr.warning(value);
                    }
                }
                else
                {
                    // エラーメッセージを表示
                    toastr.warning(subject);
                }
            }
            return;
        }else {
            // レスポンスされたエラーを表示する
            module.exports.responseError(xhr);
            // システムエラーの表示
            module.exports.systemError(xhr.status);
        }
    } catch (e) {
        // レスポンスされたエラーを表示する
        module.exports.responseError(xhr);
        // システムエラーの表示
        module.exports.systemError(xhr.status);
    }
};
/**
 * inputなどを持たない場合の省略したエラー表示を行う
 *
 * @param {*} xhr
 * @param {*} status
 * @param {*} errorThrown
 */
exports.omitted = function (xhr, status, errorThrown) {
    try {
        // Jsonのパースが行われていない場合はTextを元にパースする
        if (xhr.responseJSON === null) {
            xhr.responseJSON = JSON.parse(xhr.responseText);
        }
        // BadRequest時
        if (xhr.status == 400) {
            let json = xhr.responseJSON;
            let subject = json.subject;
            // エラーメッセージを表示
            toastr.warning(subject);
            // レスポンスされたエラーを表示する
            module.exports.responseError(xhr);
            // システムエラーを表示
            module.exports.systemError(xhr.status);
        } else {
            // レスポンスされたエラーを表示する
            module.exports.responseError(xhr);
            // システムエラーを表示
            module.exports.systemError(xhr.status);
        }
    } catch (e) {
        // レスポンスされたエラーを表示する
        module.exports.responseError(xhr);
        // システムエラーを表示
        module.exports.systemError(xhr.status);
    }
}
exports.responseError = function(xhr) {
    try {
        let res = JSON.parse(xhr.responseText);
        $.each(res.errors, function (i, message) {
            // エラーメッセージを表示
            toastr.warning(message);
        });
    } catch (e) {
        // システムエラーを表示
        module.exports.systemError(xhr.status);
    }
}
exports.systemError = function(status, modalId = '#system-error-modal') {
    // モーダルのID取得とフォームIDを生成
    var action = $(modalId).data('action') + '/' + status;
    // モーダルのコンテンツを空に
    $(modalId + ' .modal-content').empty();
    // 送信
    _ajax.get(action, function (results) {
        $(modalId + ' .modal-content').html(results);
        $(document).on('click', '#system-error-reload', function() {
            location.href = location.href.split(/[?#]/)[0];
        });
        _modal.show(modalId);
    }, null, 'html');
}
/**
 * エラーメッセージの表示処理を行う
 * @param {*} selector
 * @param {*} message
 */
exports.message = function (selector, messages) {
    $.each(messages, function(i, message) {
        let hasSameMessage = false;
        $(selector).children().each(function() {
            if ($(this).text() == message) {
                hasSameMessage = true;
                return false;
            }
        });
        if (hasSameMessage) {
            return false;
        }
        // コピークラスを複製しエラーを格納する
        let msg = $(selector).find('.copy').clone();
        msg.removeClass('copy');
        msg.text(message);
        $(selector).append(msg);
    });
};
/**
 * エラーの全削除処理を行う
 */
exports.reset = function () {
    // コピー以外のエラーのエレメントを削除する
    $('.invalid-feedback:not(.copy)').each(function(i, elm) {
        elm.remove();
    });
};
