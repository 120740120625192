/**
 * URLからパラメーターを取得する
 * @param {*} url 
 */
exports.get_parameters = function(url) {
    let params = [];
    if (url.indexOf('?') < 0) {
        return params;
    }
    search = url.split('?')[1];
    let searchs = search.split('&');
    searchs.forEach(function (value) {
        let values = value.split('=');
        param = {};
        param.name = values[0];
        param.value = values[1];
        params.push(param);
    });
    return params;
}
/**
 * 検索パラメーターを生成し現在のURLにセットする
 * @param {*} replace 
 */
exports.set_parameters = function(replace) {
    let params = module.exports.create_parameters(location.search);

    params = params.filter(name_value_pair => !name_value_pair.name.includes('[]'));
    replace.forEach(function (name_value_pair) {
        if(name_value_pair.name.includes('[]'))
        {
            params.push(name_value_pair);
            return;
        }
        // 同じnameの値があるかを検索
        let index = params.findIndex((v) => v.name === name_value_pair.name);
        if (index >= 0) {
            if (name_value_pair.value.length > 0) {
                // 同じnameの値で値がある場合、フォームの値を最優先
                params.splice(index, 1, name_value_pair);
            } else {
                // 同じnameの値で値がない場合、削除する
                params.splice(index, 1);
            }
        } else {
            // 存在しない場合は値が指定されているものだけセットする
            if (name_value_pair.value.length > 0) {
                params.push(name_value_pair);
            }
        }
    });
    let url = module.exports.create_url('', params);
    // urlの変更
    history.replaceState('', '', url);
    history.pushState('', '', url);
};
/**
 * 検索パラメータを削除する
 * @param {*} removes 
 */
 exports.remove_parameters = function(url, removes) {
    let params = module.exports.create_parameters(url);
    let urls = url.split('?');
    url = urls[0];
    removes.forEach(function (key) {
        // 同じnameの値があるかを検索
        let index = params.findIndex((v) => v.name === key);
        if (index >= 0) {
            params.splice(index, 1);
        }
    });
    let current = module.exports.create_url('', params);
    // urlの変更
    history.replaceState('', '', current);
    history.pushState('', '', current);
    return module.exports.create_url(url, params);
}
/**
 * 値から検索パラメーターの配列を返す
 * @param {*} url 
 * @returns 
 */
exports.create_parameters = function(url) {
    let urls = url.split('?');
    let params = [];
    if (urls.length >= 2) {
        let current = urls[1];
        let currentParams = [];
        if (current.length > 0) {
            currentParams = current.split('&');    
        }
        currentParams.forEach(function (value) {
            let values = value.split('=');
            if (values[1].length > 0) {
                param = {};
                param.name = values[0];
                param.value = values[1];
                params.push(param);
            }
        });
    }
    return params;
}
/**
 * URLにパラメータをセットしたURLを返す
 * @param {*} url 
 * @param {*} params 
 * @returns 
 */
exports.create_url = function(url, params) {
    let tmp = [];
    $.each(params, function(index, search) {
        if (search.name != '_token') {
            tmp.push(search.name + '=' + search.value);
        }
    });
    return url + '?' + tmp.join('&');
}
/**
 * サイドバーのURLを生成する
 * @param {*} url 
 * @param {*} is_extend 
 * @param {*} replace 
 * @param {*} deletes 
 * @returns 
 */
exports.create_affiliation_url = function(url, is_extend, replace, deletes = []) {

    let paramStr = (is_extend ? location.search.replace('?', '') : '');
    let currentParams = (paramStr.length > 0 ? paramStr.split('&') : []);
    let params = [];
    currentParams.forEach(function (value) {
        let values = value.split('=');
        if (values[0].length > 0) {
            param = {};
            param.name = values[0];
            param.value = values[1];
            params.push(param);
        }
    });
    // キーが指定されている場合は削除
    if (deletes.length) {
        deletes.forEach(function (value) {
            let index = params.findIndex((v) => v.name === value);
            if (index >= 0) {
                delete params[index];
                params = params.filter(Boolean);
            }
        });
    }
    $.each(replace, function(index, json) {
        params = json_put(params, 'name', json);
    })
    // パラメーター化
    let tmpParams = [];
    $.each(params, function(index, search) {
        if (search.name != '_token') {
            tmpParams.push(search.name + '=' + search.value);
        }
    });
    url = (url.indexOf('?') >= 0 ? url.split('?')[0] : url);
    if (!url.length) {
        url = (location.href.indexOf('?') >= 0 ? location.href.split('?')[0] : location.href);
    }
    if (tmpParams.length > 0) {
        url += '?' + tmpParams.join('&');
    }
    return url;
}