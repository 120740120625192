
import jsPDF from 'jspdf';

import html2canvas from 'html2canvas';

import Chart from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.pluginService.register(annotationPlugin);

// チャートの格納先
var chart;

/**
 * 現在のrouteの判定を行う
 * @param {*} name
 * @returns
 */
window.is_route = function (name) {
    return ($('meta[name=route]').attr('content') == name);
};
/**
 * HTMLをPDFで出力する
 * @param {*} fileName
 * @param {*} selector
 */
window.download_html_to_pdf = function(fileName, selector) {
    var pdf = new jsPDF('p', 'px', 'a4', true);
    // 各サイズ
    var pageWidth = pdf.internal.pageSize.width;
    var pageHeight = pdf.internal.pageSize.height ;
    var displayedWidth = $(selector).width();
    var displayedHeight = $(selector).height();
    var rate = pageWidth / displayedWidth;
    // 表示するコンテンツ高さ
    var contentsHeight = Math.ceil(displayedHeight * rate);
    // ページ数
    var pageCount = Math.ceil(contentsHeight / pageHeight);
    html2canvas(document.querySelector(selector)).then(capture => {
        var imgData = capture.toDataURL('image/png');
        // １ページ目に登録
        pdf.addImage(imgData, 'PNG', 0, 0, pageWidth, contentsHeight, '', 'FAST');
        // １ページ以上ある場合
        if (pageCount > 1) {
            // ２ページ目以降をセットする
            for (var i = 2; i <= pageCount; i++) {
                pdf.addPage();
                pdf.setPage(i);
                pdf.addImage(imgData, 'PNG', 0, -(pageHeight * (i - 1)), pageWidth, contentsHeight, '', 'FAST');
                //pdf.addImage(imgData, 'PNG', 0, , pageWidth, pageHeight);
            }
        }
        window.open(pdf.output('bloburl', {filename: fileName}));
    });
}
/**
 * HTMLをPDFで出力する（１ページにまとめる）
 * @param {*} fileName
 * @param {*} selector
 */
 window.download_html_to_pdf_single_page = function(fileName, selector) {
    var pdf = new jsPDF('p', 'px', 'a4', true);
    // 各サイズ
    var pageWidth = pdf.internal.pageSize.width;
    var pageHeight = pdf.internal.pageSize.height;
    var displayedHeight = $(selector).height();
    if (displayedHeight > pageHeight) {
        displayedHeight = pageHeight;
    }
    html2canvas(document.querySelector(selector)).then(capture => {
        var imgData = capture.toDataURL('image/png');
        // １ページ目に登録
        pdf.addImage(imgData, 'PNG', 0, 0, pageWidth, displayedHeight, '', 'FAST');
        window.open(pdf.output('bloburl', {filename: fileName}));
    });
}
/**
 * HTMLをPDFで出力する（元の比率を保ち複数ページにまとめる）
 * @param {*} fileName
 * @param {*} selector
 */
 window.download_html_to_pdf_single_page_keep_rate = function(fileName, selector) {
    var pdf = new jsPDF('p', 'px', 'a4', true);
    // 各サイズ
    var pageWidth = pdf.internal.pageSize.width;
    var pageHeight = pdf.internal.pageSize.height ;
    var displayedWidth = $(selector).width();
    var displayedHeight = $(selector).height();
    var rate = pageHeight / displayedHeight;
    // 表示位置とコンテンツ幅
    var contentsWidth = Math.ceil(displayedWidth * rate);
    var x = Math.ceil((pageWidth - contentsWidth) / 2);
    html2canvas(document.querySelector(selector)).then(capture => {
        var imgData = capture.toDataURL('image/png');
        if (contentsWidth > pageWidth) {
            // １ページ目に登録
            pdf.addImage(imgData, 'PNG', 0, 0, pageWidth, 0, '', 'FAST');
        } else {
            // １ページ目に登録
            pdf.addImage(imgData, 'PNG', x, 0, contentsWidth, 0, '', 'FAST');
        }
        window.open(pdf.output('bloburl', {filename: fileName}));
    });
}
/**
 * チャート表示を行う
 * @param {*} id
 * @param {*} type
 * @param {*} data
 * @param {*} options
 */
window.chart = function (id, type, data, options) {
    // 作成されている場合は破棄する
    if (chart) {
        chart.destroy();
    }
    // チャートを作成
    chart = new Chart(document.getElementById(id).getContext('2d'), {
        type: type,
        data: data,
        options: options
    });
    return chart;
};
/**
 * コンテンツの読み込み回数のカウンター
 */
window.load_counter = 0;
/**
 * 検索条件からコンテンツを表示する
 * @param {*} callback
 * @param {*} selector
 * @param {*} replaceSelector
 * @param {*} isExtendPageQuery
 */
window.load_contents = function (callback = null, isExtendPageQuery = true, selector = '#search-form', replaceSelector = '#contents',isShowToastErrors = false) {
    let action = '';
    let datas = [];
    if ($(selector).length) {
        $(selector).append($('<input></input>', {
            type: 'hidden',
            name: $('#toolbar-keyword-search').attr('name'),
            value: $('#toolbar-keyword-search').val(),
        }));
        // 検索フォームの値をURLにセットする
        const fn_keyword_encode = (para) => para.name === 'keyword' ? Object.assign(para,{value: encodeURIComponent(para.value)}) : para;
        _request.set_parameters($(selector).serializeArray().map(fn_keyword_encode));
        // 現在のURLのGetパラメーターを引き継ぐ
        action = $(selector).attr('action');
        action = extend_url_query(action);
        $(selector).attr('action', action);
        datas = $(selector).serializeArray()
    } else if ($(replaceSelector).length) {
        action = $(replaceSelector).data('action');
        action = extend_url_query(action);
        $(replaceSelector).attr('action', action);
    } else {
        return;
    }
    if (!isExtendPageQuery) {
        action = _request.remove_parameters(action, ['page']);
    }
    _ajax.post(action, datas, function (results) {
        // インクリメント
        load_counter ++;
        $(replaceSelector).html(results);
        initialize();
        // backdropだけ戻るケースを考慮し削除する
        $('.modal-backdrop').remove();
        // コールバック指定時
        if (callback !== null) {
            callback();
        }
        // 読み込み回数に応じてイベントを変更
        if (load_counter > 1) {
            // event発行
            $(document).trigger('bss:reload');
        } else {
            // event発行
            $(document).trigger('bss:onload');
        }
       // body要素に'modal-open'クラスがある場合
       if ($('body').hasClass('modal-open')) {
            // 'modal-open'クラスを削除
            $('body').removeClass('modal-open');
        }
    }, function(xhr, status, errorThrown){
        _error.set(selector, xhr, status, errorThrown,true,isShowToastErrors);
    }, 'html');
};
/**
 * 配列にキーが存在するか確認し存在していれば上書き存在しない場合は追加する
 * @param {*} json
 * @param {*} key
 * @param {*} add
 * @returns
 */
window.json_put = function (json, key, add) {
    let index = json.findIndex((v) => v[key] === add[key]);
    if (index < 0) {
        json.push(add);
    } else {
        json.splice(index, 1, add);
    }
    return json;
}
/**
 * URLに現在のGETクエリを追加する
 * @param {*} url
 * @returns
 */
window.extend_url_query = function (url) {
    if (url != null) {
        url = (url.indexOf('?') >= 0 ? url.split('?')[0] : url);
        url += location.search;
    }
    return url;
}
/**
 * Formによるポストを行う
 * @param {*} url
 * @param {*} params
 * @param {*} method
 */
window.post = function (url, params, method = 'post') {
    const form = $('<form></form>', {
        class: 'display-none',
        method: method,
        action: url
    });
    params.forEach(value => {
        form.append($('<input></input>', {
            type: 'hidden',
            name: value.name,
            value: value.value
        }));
    });
    $('body').append(form);
    form.trigger('submit');
}
/**
 * bs-stepperのステップの切り替えを行う
 * @param {*} triggerSelector
 */
 window.bs_step_trigger = function(triggerSelector) {
    let header = $(triggerSelector).parents('.bs-stepper-header');
    let show = create_id($(triggerSelector).attr('aria-controls'));
    let hide = $(show).parents('.bs-stepper-content').find('.show');
    $(header).find('.step').removeClass('active');
    $(triggerSelector).parents('.step').addClass('active');
    $(hide).removeClass('show');
    $(hide).addClass('fade');
    $(show).removeClass('fade');
    $(show).addClass('show');
}
/**
 * #で始まらない場合は付与する
 * @param {*} selector
 */
window.create_id = function(selector) {
    if (selector != null) {
        return (selector.startsWith('#') ? selector : '#' + selector);
    } else {
        return '';
    }
}
