/**
 * 在庫管理ページ用のスクリプト
 */
if (is_route('stock.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function () {
        stock_event_set();
    });
}

window.stock_event_set = function () {

    // 編集画面表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id']);
    });

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function (results) {
        }, function () {
        });
    });

    // 履歴表示時
    $(document).on('click', '[data-history-modal]', function () {
        _modal.create(this, 'history-modal', ['id']);
    });

    // ダウンロード画面表示
    $(document).on('click', '[data-download-setting-modal]', function () {
        _modal.create(this, 'download-setting-modal', ['id']);
    });

    // CSV一括ダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#stock-list').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });

    /*---- Excel出力ボタン押下時 ----*/
    // 入出庫履歴Excel出力時
    $(document).on('click', '#search-form-action_excel_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#stock-history-list').serializeArray();
        // BlobでExcel出力を別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    });
}
