import { setFileDownloadEvent } from "../util/download";

/**
 * 請求管理ページ用のスクリプト
 */
if (is_route('bill.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function () {
        bill_event_set();
        bill_setting_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.bill_event_set = function () {

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['id']);
    });

    // 納品書印刷時
    $(document).on('click', '#search-form-action_print', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#bill-list').serializeArray();

        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    // 納品書Excel出力
    const getActionAndDataCallback = function (eventElem) {
        const action = extend_url_query($(eventElem).data('action'));
        // 検索フォームを配列化
        const data = $('#bill-list').serializeArray();
        
        return [action, data];
    };
    setFileDownloadEvent('#search-form-action_download_delivery_slip', getActionAndDataCallback);

    // 編集表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id']);
    });

    // 商品編集表示時
    $(document).on('click', '[data-setting-detail-modal]', function () {
        _modal.create(this, 'setting-detail-modal', ['id']);
    });

    // 保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function (results) {
            $('[data-detail-modal][data-id=' + results.bill.id + ']').trigger('click');
        }, function () {
            bill_set_steper_error();
        });
    });
    // 削除
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });

    // 一括のチェックボックスクリック時
    $(document).on('click', '#bill-all-list', function () {
        $(".bill-list").prop('checked', $(this).prop('checked'));
    });
    // 一覧のチェックボックスクリック時
    $(document).on('click', '.bill-list', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });

    // 削除確認時
    $(document).on('click', '[data-delete_confirm-modal]', function () {
        var checks = document.getElementsByClassName('bill-list');
        //チェックされた値を配列に格納
        var arr = [];
        for (i = 0; i < checks.length; i++) {
            if (checks[i].checked === true) {
                arr.push(checks[i].value);
            }
        }
        let datas = { 'ids': arr };
        if (null != datas) {
            _modal.create_with_data(this, 'delete_confirm-modal', datas);
        }
    });

    // 請求データダウンロード時
    $(document).on('click', '#search-form-action_download_bill', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#bill-list').serializeArray();

        // BlobでExcelダウンロード
        _ajax.blob(action, datas, function (blob, status, xhr) {
            let fileName = decodeURIComponent(xhr.getResponseHeader('filename'));
            // aタグを経由してダウンロード
            var a = document.createElement("a");
            var blobUrl = window.URL.createObjectURL(new Blob([blob], {
                type: blob.type
            }));
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = blobUrl;
            a.download = fileName;
            a.click();
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    // 設定表示時
    $(document).on('click', '[data-csv-save-modal]', function () {
        let modalId = '#bill-csv-setting-modal';
        let formId = '#bill-csv-setting-modal-form';
        let action = $(formId).attr('action');
        let datas = new FormData($(formId).get(0));
        // 送信前にエラーをリセット
        _error.reset()
        // 送信
        _ajax.file(action, datas, function (results) {
            // 完了メッセージをセットする
            _complete.set(results);
            // モーダルを閉じる
            _modal.hide(modalId);
            // コンテンツの読込
            load_contents();
        }, function (xhr, status, errorThrown) {
            // エラーをセットする
            _error.omitted(xhr, status, errorThrown);
        });
    });

    $(document).on('click', '.transfered .btn-group', function (event) {
        event.preventDefault();
    });
}
/**
 * 設定モーダル表示時のイベント設定
 * @uses ダッシュボードからモーダル表示する際にも呼び出される
 */
window.bill_setting_event_set = function () {

    /*---- ステップボタンクリック時（新規登録時） ----*/

    // 前へ
    $(document).on('click', '[data-prev-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let prevPart = $(this).data('prev-step');
        let prevPartTrigger = create_id(prevPart + '-trigger');
        let prevPartSubmit = create_id(prevPart + '-submit');
        $(currentPartSubmit).addClass('display-none');
        $(prevPartSubmit).removeClass('display-none');
        bs_step_trigger(prevPartTrigger);
    });

    // 次へ
    $(document).on('click', '[data-next-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let thisPart = $(this).data('confirm-step');
        let nextPart = $(this).data('next-step');
        let nextPartTrigger = create_id(nextPart + '-trigger');
        let nextPartSubmit = create_id(nextPart + '-submit');
        // ModalとPartセレクターの指定
        let modalId = '#bill-setting-modal';
        let formId = '#bill-setting-modal-form';
        let action = $(formId).data('confirm-action');
        // 確認対象のパートをパラメータにセット
        $('#bill-setting-modal-confirm_step').val(thisPart);
        // 送信前にエラーをリセット
        _error.reset()
        bill_set_steper_error(false);
        // 確認用のpost送信
        _ajax.confirm_submit(formId, action, function (results) {
            $(currentPartSubmit).addClass('display-none');
            $(nextPartSubmit).removeClass('display-none');
            setConfirm(thisPart);
            bs_step_trigger(nextPartTrigger);
        }, function (xhr, status, errorThrown) {
            // エラーをセットする
            _error.set(modalId, xhr, status, errorThrown);
            // ステップエラーを表示
            bill_set_steper_error();
        });
    });

    /*---- 商品の追加ボタンを押下(入力項目を追加する) ----*/

    $(document).on('click', 'button[name="add_product"]', function () {
        addProduct(this);
    });

    /*---- 商品のセレクト変更時 ----*/
    $(document).on('change', 'select.product-select', function () {
        bill_setProduct(this, { 'id': $(this).val() });
    });

    /*---- 単価変更時 ----*/
    $(document).on('change', '.product_sell_price input[type="number"]', function () {
        let $parent = $(this).closest('.product_sell_price');
        $parent.find('span.number').text($(this).val());
        $parent.find('input[type="hidden"]').val($(this).val());
        bill_calcAmountPrice(this);
        bill_calcTotals(this);
    });

    /*---- 商品の数量変更時 ----*/
    $(document).on('change', '.product_amount input.number-input', function () {
        bill_calcAmountPrice(this);
        bill_calcTotals(this);
    });

    /*---- 削除チェック変更時 ----*/
    $(document).on('change', '.product_delete input', function () {
        bill_calcTotals(this);
    });

    /**
     * 金額を計算して反映
     * @param {*} elm
     */
    window.bill_calcAmountPrice = function (elm) {
        let productRow = $(elm).closest('tr.product-row');
        let productPrice = Number(productRow.find('td.product_sell_price span.number').text());
        let productAmount = Number(productRow.find('td.product_amount .number-input').val());

        // 金額
        productRow.find('td.product_amount_price span.text').text((productPrice * productAmount).toLocaleString());
        productRow.find('td.product_amount_price span.number').text(productPrice * productAmount);
    }


    /**
     * 小計、消費税、合計を計算して反映
     */
    window.bill_calcTotals = function (elm) {
        // 小計
        let productTable = $(elm).closest('table');
        let subtotal = 0;
        productTable.find('tr.product-row').each(function () {
            if (true == $(this).find('.product_delete input').prop('checked')){
                return true;
            }
            subtotal += Number($(this).find('td.product_amount_price span.number').text());
        });
        productTable.find('td.product_subtotal span.text').text(subtotal.toLocaleString());
        productTable.find('td.product_subtotal span.number').text(subtotal);

        // 消費税
        let tax_rate = Number($('#tax_rate').text());
        let tax = Math.floor(tax_rate / 100 * subtotal);
        productTable.find('td.product_tax span.text').text(tax.toLocaleString());
        productTable.find('td.product_tax span.number').text(tax);

        // 合計
        productTable.find('td.product_total span.text').text((tax + subtotal).toLocaleString());
        productTable.find('td.product_total span.number').text(tax + subtotal);

    }

    // 商品を追加する
    function addProduct(elem, callback = null) {
        var table = $('table#detail-bill_edit');
        var count = table.find('.product-row').length;
        var tr = $($('#template-product-row').html());

        // 追加要素の初期化
        tr.find('input').each(function() {
            // チェックボックスの場合はvalueを残す
            if (!'checkbox' == $(this).attr('type')){
                $(this).val('');
            }

            if ($(this).hasClass('number-input-value')){
                // name書き換え
                let name = $(this).attr('name');
                name = name.replace(/products\[\d\]/, 'products[' + count + ']');
                $(this).attr('name', name);
            } else if ($(this).hasClass('number-input')){
                var id = $(this).attr('id');
                var newId = id.replace(/products_0/g , 'products_' + count);
                var error = tr.find('#' + id + '-errors');

                // ID書き換え
                $(this).attr('id', newId);
                // error初期化
                if (error.length > 0) {
                    error.attr('id', newId + '-errors');
                    error.find('span').html('');
                }
            } else{
                var id = $(this).attr('id');
                var newId = id.replace(/products_0/g , 'products_' + count);
                var label = tr.find('label[for="' + id + '"]');
                var error = tr.find('#' + id + '-errors');

                // ID書き換え
                $(this).attr('id', newId);
                label.attr('for', newId);

                // name書き換え
                let name = $(this).attr('name');
                if (name){
                    name = name.replace(/products\[\d\]/, 'products[' + count + ']');
                    $(this).attr('name', name);
                }

                // error初期化
                if (error.length > 0) {
                    error.attr('id', newId + '-errors');
                    error.find('span').html('');
                }
            }
        });

        tr.find('select').each(function() {
            var id = $(this).attr('id');
            var newId = id.replace(/products_0/g , 'products_' + count);
            var label = tr.find('label[for="' + id + '"]');
            var error = tr.find('#' + id + '-errors');
            // ID書き換え
            $(this).attr('id', newId);
            label.attr('for', newId);

            // name書き換え
            let name = $(this).attr('name');
            name = name.replace(/products\[\d\]/, 'products[' + count + ']');
            $(this).attr('name', name);

            // error初期化
            if (error.length > 0){
                error.attr('id', newId + '-errors');
                error.find('span').html('');
            }
        });

        // 商品選択項目呼び出し
        let productSelect = tr.find('.product-select');
        let action = $(elem).data('action');
        let subjectId = $('#subject_id').text();
        if(subjectId){
            let datas = {
                conditions: {
                    is_visible: 1,
                    subject_id: subjectId
                },
                select_text:'distributor_product_name'
            }
            // 送信
            _ajax.post(action, datas, function (results) {
                productSelect.empty();
                productSelect.select2({
                    width: "100%",
                    data: results.options
                });
                if (callback != null) {
                    callback();
                }
            }, function (xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            });
        }
        // 最後に追加
        table.find('.tr-target').before(tr);

        initialize();
    }

    /**
     * 商品レコードに情報を反映
     * @param {*} elm
     * @param {*} datas
     * @param {*} callback
     */
    window.bill_setProduct = function (elm, datas = {}, callback = null) {
        let productRow = $(elm).closest('tr.product-row');
        if (productRow.length > 0)
        {
            // 初期化
            productRow.find('td.product_amount input[type="text"]').attr('max', 0);
            productRow.find('td.product_amount input[type="text"]').val(0);
            productRow.find('td.product_amount input[type="hidden"]').val(0);
            productRow.find('td.product_name input[name*="[product_name]"]').val();
            productRow.find('td.product_code span.text').empty();
            productRow.find('td.product_code input').val('');
            productRow.find('td.product_sell_price span.text').empty();
            productRow.find('td.product_sell_price span.number').empty();
            productRow.find('td.product_sell_price input[type="number"]').val('');
            productRow.find('td.product_sell_price input[type="hidden"]').val('');
            productRow.find('td.product_amount_price span.text').empty();
            productRow.find('td.product_amount_price span.number').empty();
            productRow.find('td.product_amount_price input').val('');

            let action = $(elm).data('action');
            _ajax.post(action, datas, function (results) {
                if (!results.model) {
                    return false;
                }
                productRow.find('td.product_name input[name*="[product_name]"]').val(results.model.distributor_product_name);
                productRow.find('td.product_code span.text').text(results.model.distributor_product_code);
                productRow.find('td.product_code input').val(results.model.distributor_product_code);
                productRow.find('td.product_amount input[type="text"]').attr('max', null);
                productRow.find('td.product_sell_price span.text').text(results.sell_price.toLocaleString());
                productRow.find('td.product_sell_price span.number').text(results.sell_price);
                productRow.find('td.product_sell_price input[type="number"]').val(results.sell_price);
                productRow.find('td.product_sell_price input[type="number"]').attr('max', null);
                productRow.find('td.product_sell_price input[type="hidden"]').val(results.sell_price);
                bill_calcAmountPrice(elm);
                bill_calcTotals(elm);
                if (callback != null) {
                    callback(elm);
                }
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }
    }
}

/**
 * ステッパーへのエラー表示処理
 * @param {*} showError
 */
 window.bill_set_steper_error = function (showError = true) {
    if (showError) {
        $('#base-part-trigger').toggleClass('error', ($('#base-part').find('.invalid-feedback:not(.copy)').length > 0));
    } else {
        $('#base-part-trigger').removeClass('error');
    }
}
