/**
 * お知らせ設定ページ用のスクリプト
 */
 if (is_route('information.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        information_event_set();
        information_setting_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.information_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id'], false, function(results) {
            information_setting_event_set();
        });
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal');
    });
}
/**
 * 設定モーダル表示時のイベント設定
 */
 window.information_setting_event_set = function () {

    function setDisplayOfValueInput(elm) {
        let input = $('input[name=' + $(elm).data('target') + ']');
        if ($(input).attr('type') != 'hidden') {
            $(input).toggleClass('display-none', ($(elm).val() != '1'));
            if ($(input).val() == '') {
                $(input).val($(input).data('default'));
            }
        }
    }
    // switch
    $(document).off('change', '.information_item_flag');
    $(document).on('change', '.information_item_flag', function() {
        setDisplayOfValueInput(this);
    });
    $('.information_item_flag:checked').each(function () {
        setDisplayOfValueInput(this);
    });
}