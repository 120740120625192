const { each } = require("jquery");

/**
 * 契約情報管理ページ用のスクリプト
 */
if (is_route('bss_contract.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function () {
        bss_contract_event_set();
    });
}

/**
 * ページ読み込み時のイベント設定
 */
window.bss_contract_event_set = function () {

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['id'],true);
    });

    //解約表示時
    $(document).on('click', '[data-cancel-modal]', function () {
        // 解約モーダルを表示
        _modal.create(this, 'cancel-modal', ['id']);
    });

    //プラン変更表示時
    $(document).on('click', '[data-edit-modal]', function () {
        // プラン変更モーダルを表示
        _modal.create(this, 'edit-modal', ['id']);
    });

    //CTOS番号編集表示時
    $(document).on('click', '[data-cotos_no-modal]', function () {
        // CTOS番号編集モーダルを表示
        _modal.create(this, 'cotos_no-modal', ['id','first_id']);
    });
    
    //契約日、請求開始日、解約日編集時表示
    $(document).on('click', '[data-change_date-modal]', function () {
        _modal.create(this, 'change_date-modal', ['id']);
    });
    
    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        let action = $(this).data('action');
        let modalId = create_id($(this).data('setting-modal'));
        var checks = document.getElementsByClassName('contract_list');
        //チェックされた値を配列に格納
        var arr = [];
        for (i = 0; i < checks.length; i++) {
            if (checks[i].checked === true) {
                arr.push(checks[i].value);
            }
        }
        let datas = { 'ids': arr };
        // モーダルのコンテンツを空に
        $(modalId + ' .modal-content').empty();

        // 送信
        _ajax.post(action, datas, function (results) {
            $(modalId + ' .modal-content').html(results);
            initialize();
            _modal.show(modalId);
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        }, 'html');

    });

    /*---- 一括選択チェックボックス操作時 ----*/

    // 一括のチェックボックスクリック時
    $(document).on('click', '#contract-all-list', function () {
        $(".contract_list").each(function () {
            if ($(this).prop('disabled') == false) {
                $(this).prop('checked', $("#contract-all-list").prop('checked'));
            }
        })
    });
    // 一覧のチェックボックスクリック時
    $(document).on('click', '.contract_list', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });

    /*---- 設定保存時 ----*/
    //請求日変更時
    $(document).on('change', '#search-form-month', function (e) {
        let billed_at = document.confirm.elements['billed_at'].value;//選択した請求日を取得
        let action = $(this).data('action');;
        var ids = document.getElementsByClassName('model_id');
        for (i = 0; i < ids.length; i++) {
            let datas = {
                'id': ids[i].value,
                'billed_at': billed_at
            }
            // 送信
            _ajax.post(action, datas, function (results) {
                let target_data_tr = document.getElementById('contract_result' + datas.id);
                let target_data_th = document.getElementById('contract_check' + datas.id);
                if (results == 'true') {
                    target_data_tr.classList.add('contract_done');
                    target_data_th.classList.add('contract_checked');
                } else {
                    target_data_tr.classList.remove('contract_done');
                    target_data_th.classList.remove('contract_checked');
                }
            }, function (xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            }, 'html');
        }
    });

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function (results) {
            $('[data-detail-modal=contract-detail-modal][data-id=' + results.first_contract_id + ']').trigger('click');
        }, function () {
            contract_set_steper_error();
        });
    });

    /*---- CSV一括ダウンロード ----*/
    // 契約情報ダウンロード時
    $(document).on('click', '[data-contract_download_search-modal]', function () {
        _modal.create(this, 'contract_download_search-modal', [], false, () => {
            $('#contract_download-modal-start_contracted_at').prop('disabled', true).val('');
            $('#contract_download-modal-end_contracted_at').prop('disabled', true).val('');
            $('#contract_download-modal-start_billing_start_at').val(moment().startOf('month').format('YYYY/MM/DD'));
            $('#contract_download-modal-end_billing_start_at').val(moment().endOf('month').format('YYYY/MM/DD'));
        });
    });

    // CSV一括ダウンロード時
    $(document).on('click', '[data-contract_download-modal]', function () {
        let modalForm = $(this).parents('.modal-content').find('form');
        // アクションの取得
        let action = extend_url_query(modalForm.attr('action'));
        // 注文日時情報
        let modalDatas = modalForm.serializeArray();
        // 検索フォームを配列化
        let searchDatas = $('#search-form').serializeArray();
        // チェックされたの確認
        let checkDatas = $('#contract-list').serializeArray();
        let datas = [...modalDatas, ...searchDatas, ...checkDatas];

        let isValid = false;
        modalForm.find('input[type="text"]:visible').each(function () {
            if ($(this).val().trim() !== '') {
                isValid = true;
                return false;
            }
        });
        if (!isValid) {
            alert('日付範囲を記入してください。');
            return;
        }

        // ポスト送信を実行
        _ajax.blob(action, datas, function (results) {
            if(results.size){
                    let link = document.createElement("a");
                    link.download = '契約情報.csv';
                    link.href = URL.createObjectURL(results);
                    link.click();
                    URL.revokeObjectURL(link.href);
                    link = null;
            }else{
                _complete.warningSet({warnings:['検索された契約情報はありません。']});
            }
            load_contents();
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 契約日基準と請求開始日基準の切替 ----*/
    $(document).on('change', '[name="date_basis"]', function () {
        const startOfMonth = moment().startOf('month').format('YYYY/MM/DD');
        const endOfMonth = moment().endOf('month').format('YYYY/MM/DD');

        const $contractStart = $('#contract_download-modal-start_contracted_at');
        const $contractEnd = $('#contract_download-modal-end_contracted_at');
        const $billingStart = $('#contract_download-modal-start_billing_start_at');
        const $billingEnd = $('#contract_download-modal-end_billing_start_at');

        function updateDateInputs(enableContract, enableBilling) {
            $contractStart.prop('disabled', !enableContract).val(enableContract ? startOfMonth : '');
            $contractEnd.prop('disabled', !enableContract).val(enableContract ? endOfMonth : '');
            $billingStart.prop('disabled', !enableBilling).val(enableBilling ? startOfMonth : '');
            $billingEnd.prop('disabled', !enableBilling).val(enableBilling ? endOfMonth : '');

            $('.contract-date-range').toggle(enableContract);
            $('.billing-start-date-range').toggle(enableBilling);
        }

        if ($('#contract_download-modal-basis_contract').is(':checked')) {
            updateDateInputs(true, false);
        } else if ($('#contract_download-modal-basis_billing').is(':checked')) {
            updateDateInputs(false, true);
        }
    });

}


