/**
 * 交換スケジュールページ用のスクリプト
 */
if (is_route('replacement_schedule.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        replacement_schedule_event_set();
        replacement_schedule_setting_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.replacement_schedule_event_set = function() {

    // 設定表示
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['holding_code', 'company_code', 'shipping_section_code'], true);
    });

    // CSVダウンロード
    $(document).on('click', '#search-form-action_csv_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });
    $(document).on('submit', '#display-setting-modal-form', function (event) {
        event.preventDefault();
    });
}

/**
 * 設定モーダル表示時のイベント設定
 * @uses ダッシュボードからモーダル表示する際にも呼び出される
 */
window.replacement_schedule_setting_event_set = function () {

    // 一括のチェックボックスクリック
    $(document).on('change', '.is_checked_all', function () {
        $(".is_checked").prop('checked', $(this).prop('checked')).change();
    });

    // チェックボックスクリック
    $(document).on('change', '.is_checked', function () {
        // チェックが入ったらフォームを活性化
        $target = $(this).parents('tr').find('select');
        if($(this).prop('checked')){
            $target.removeAttr('disabled');
        }else{
            $target.attr('disabled', 'disabled');
        }
    });

    // メール詳細
    $(document).on('click', '[data-mail-detail-modal]', function () {
        _modal.create(this, 'mail-detail-modal', ['id', 'mail_key', 'mail_index']);
    });

    // メール編集
    $(document).on('click', '[data-mail-setting-modal]', function () {
        // エラーリセット
        _error.reset()

        // formをデータ化
        let datas = $('#replacement_schedule-setting-modal-form').serializeArray();
        // データ追加
        datas.push({'name':'mail_key','value':$(this).data('mail_key')});

        let modalId = create_id($(this).data('mail-setting-modal'));
        let action = $(this).data('action');
        // 送信
        _ajax.post(action, datas, function (results) {
            // モーダルのコンテンツを空にする
            $(modalId + ' .modal-content').empty();
            // モーダルにコンテンツを追加する
            $(modalId + ' .modal-content').html(results);
            initialize();
            _modal.show(modalId);
        }, function (xhr, status, errorThrown) {
            // エラーをセットする
            _error.set('#replacement_schedule-setting-modal', xhr, status, errorThrown, true, true);
        }, 'html');
    });

    // メール送信
    $(document).on('click', '[data-mail-send]', function () {
        _modal.submit(this, 'mail-send', function (results) {
            $('[data-setting-modal=replacement_schedule-setting-modal][data-holding_code=' + results.holding_code + '][data-company_code=' + results.company_code + '][data-shipping_section_code=' + results.shipping_section_code + ']').trigger('click');
        }, function (xhr, status, errorThrown) {
        });
    });

    // メール編集画面用スクリプト読込
    require('../util/mail');

    // 設定画面の保存
    $(document).on('click', '[data-save-modal]', function () {
        // エラーリセット
        _error.reset()

        let datas = $('#replacement_schedule-setting-modal-form').serialize();
        let modalId = create_id($(this).data('save-modal'));
        let action = $(this).data('action');
        // 送信
        _ajax.post(action, datas, function (results) {
            // モーダルのコンテンツを空にする
            $(modalId + ' .modal-content').empty();
            // モーダルにコンテンツを追加する
            $(modalId + ' .modal-content').html(results);
            initialize();
            _modal.show(modalId);
        }, function (xhr, status, errorThrown) {
           // エラーをセットする
            _error.set('#replacement_schedule-setting-modal', xhr, status, errorThrown, true, false);
        }, 'html');
    });

    // 発送予定日キャンセル
    $(document).on('click', '[data-shipping_cancel]', function () {
        let datas = {
            id:$(this).data('id')
        };
        let action = $(this).data('action');
        // 送信
        _ajax.post(action, datas, function (results) {
            if(results.messages){
                // メッセージを表示
                _complete.set(results);
                load_contents(function () {
                    $('[data-setting-modal=replacement_schedule-setting-modal][data-holding_code=' + results.holding_code + '][data-company_code=' + results.company_code + '][data-shipping_section_code=' + results.shipping_section_code + ']').trigger('click');
                });
            }
        }, function (xhr, status, errorThrown) {
           // エラーをセットする
            _error.set('#replacement_schedule-setting-modal', xhr, status, errorThrown, true, false);
        });
    });

    // 発送予定日画面の保存
    $(document).on('click', '[data-shipping-save-modal]', function () {
        // エラーリセット
        _error.reset()

        let toEstimatedShippingAt = $('#replacement_schedule-shipping-setting-modal-to_estimated_shipping_at').val();
        $('#replacement_schedule-setting-modal-form [name="to_estimated_shipping_at"]').val(toEstimatedShippingAt);
        _modal.submit(this, 'shipping-save-modal', function (results) {
               $('[data-setting-modal=replacement_schedule-setting-modal][data-holding_code=' + results.holding_code + '][data-company_code=' + results.company_code + '][data-shipping_section_code=' + results.shipping_section_code + ']').trigger('click');
        }, function() {
        });
    });

    // グループ会社メモ編集
    $(document).on('click', '[data-memo-setting-modal]', function () {
        _modal.create(this, 'memo-setting-modal', ['id']);
    });

    // グループ会社メモ保存
    $(document).on('click', '[data-memo-save-modal]', function () {
        _modal.submit(this, 'memo-save-modal');
    });
}