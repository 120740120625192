/**
 * ログイン後にFirebaseのPush通知のトークン取得の共通処理を行う
 */

import Cookies from 'js-cookie';
import firebase from "firebase/app";
import 'firebase/messaging';

if ($('meta[name=notification]').length > 0 && is_route('dashboard.index')) {

    var firebaseConfig = {
        apiKey: "AIzaSyC_5hd6DIO_wubtSUHjfqvDPs8FEEjiJAE",
        authDomain: "bss-anest.firebaseapp.com",
        projectId: "bss-anest",
        storageBucket: "bss-anest.appspot.com",
        messagingSenderId: "335952016242",
        appId: "1:335952016242:web:4c1582aa0ca331ea8863eb",
        measurementId: "G-CSLGZE569P"
    };

    firebase.initializeApp(firebaseConfig);

    const messaging = firebase.messaging();

    $(function () {
        
        // $(document).one('click', '*:not([type=submit])', function(e) {
        //     e.preventDefault();
        //     e.stopPropagation()
        //     var click = $(this);
        //     messaging.getToken({vapidKey: 'BIGjyEz9c4u4zBptKGxAlMzrBfKQmgZdcqCvE0XdlhWaO09k5KRv3Gh-xh7wiRpMVWqNt3IXtfN8ebNx14l2plk'})
        //     .then(function(currentToken) {
        //         if (currentToken) {
        //             // 10文字のトークンを生成
        //             var shortToken = currentToken.slice(0, 10);
        //             //Cookies.remove('short_token', {samesite: 'Lax'});
        //             // // クッキーの値と異なる場合
        //             if (Cookies.get('short_token', {samesite: 'Lax'}) != shortToken) {
        //                 // 許可されトークンが取得可能な場合
        //                 let datas = [];
        //                 let action = $('meta[name=notification]').attr('content');
        //                 datas.push({'name' : 'token', 'value' : currentToken});
        //                 datas.push({'name' : 'user_agent', 'value' : navigator.userAgent});
        //                 // 送信
        //                 _ajax.post(action, datas, function (results) {
        //                     // 10文字のトークンを格納しておく
        //                     Cookies.set('short_token', shortToken, {samesite: 'Lax'});
        //                     // クリックを継続
        //                     eventContinue(click);
        //                 });
        //             }
        //         } else {
        //             // トークンが取得できない場合はクッキーのトークンを削除しそのままクリックし直す
        //             Cookies.remove('short_token', {samesite: 'Lax'});
        //             // クリックを継続
        //             eventContinue(click);
        //         }
        //     }).catch(function(error) {
        //         // 許可されなかった場合はクッキーのトークンを削除しそのままクリックし直す
        //         Cookies.remove('short_token', {samesite: 'Lax'});
        //         // クリックを継続
        //         eventContinue(click);
        //     });
        // });

        /**
         * クリックイベントの継続処理
         * @param {*} elm 
         */
        function eventContinue(elm) {
            if (elm.parents('.month-picker-inputs').length == 0) {
                $(elm).trigger('click');
            }
        }
    });
}