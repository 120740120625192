// コンテンツ読み込み後
$(document).ready(function() {
    // toアドレス追加
    $(document).on('click', '[data-append-to_emails]', function () {
        toIndex++;
        let emailInput = getInputEmail('mail-setting-modal-to_emails_' + toIndex, 'to_emails[' + toIndex + ']');
        $('#to_emails-wrapper').append(emailInput);
    });

    // ccアドレス追加
    $(document).on('click', '[data-append-cc_emails]', function () {
        ccIndex++;
        let emailInput = getInputEmail('mail-setting-modal-cc_emails_' + ccIndex, 'cc_emails[' + ccIndex + ']');
        $('#cc_emails-wrapper').append(emailInput);
    });

    // アドレス削除
    $(document).on('click', '[data-remove]', function () {
        $(this).closest('.email-address').remove();
    });

    // エレメント作成
    function getInputEmail(id, name) {
        return $(`<div class="email-address">
                    <input type="email" id="${id}" class="multi-error" name="${name}" />
                    <span class="remove-button" data-remove>削除</span>
                    <div class="" id="${id}-errors">
                        <span class="invalid-feedback copy" role="alert"></span>
                    </div>
                </div>
                `);
    }
});
